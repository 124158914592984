import React from 'react';

import { UserGroupIcon } from '@heroicons/react/24/outline';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { getClinicalAssociations } from '../api';
import { useAlert } from '../utils/context/alert';
import { useLanguage } from '../utils/context/lang';
import { useUser } from '../utils/context/user';

import { i18n } from './i18n/guidance';


const AssociationCard = ({ association, nAssociationClick, lang }) => {
  const info = association.info;
  
  return (
    <div
      onClick={() => nAssociationClick(association)}
      className={`group bg-base-100 rounded-xl overflow-hidden border border-base-200 
        transition-all duration-300 relative
        ${info.isActive 
      ? 'hover:shadow-lg cursor-pointer' 
      : 'opacity-75 hover:opacity-100'
    }`}
    >
      {/* Inactive Overlay */}
      {!info.isActive && (
        <div className="absolute inset-0 bg-base-200/50 backdrop-blur-[1px] z-10 
          flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="bg-base-100 rounded-lg shadow-lg p-4 transform translate-y-4 
            group-hover:translate-y-0 transition-transform duration-300">
            <p className="text-base-content/70 text-sm font-medium">
              {i18n.INACTIVE_GUIDANCE[lang]}
            </p>
          </div>
        </div>
      )}

      {/* Content (slightly dimmed when inactive) */}
      <div className={`relative ${!info.isActive ? 'opacity-75' : ''}`}>
        {/* Header Image */}
        <div className="aspect-[13/6] bg-gradient-to-br from-primary/5 to-secondary/5 relative overflow-hidden">
          <img src={info.image} alt={info.name} className="object-contain w-full h-full" />
        </div>

        {/* Content */}
        <div className="flex items-center p-4 gap-2">
          <UserGroupIcon className="w-5 h-5" />
          <h3 className="text-lg font-semibold line-clamp-2 flex-1">
            {association.name}
          </h3>
        </div>
      </div>
    </div>
  );
};

export function GuidanceLanding() {
  const navigate = useNavigate();
  const associations = useLoaderData();
  const showAlert = useAlert();
  const { lang } = useLanguage();
  const { user } = useUser();

  const handleAssociationClick = (association) => {
    if (association.info.isActive) {
      navigate(`/guidance/${association.path}`)
    } else {
      showAlert('error', i18n.INACTIVE_GUIDANCE[lang]);
    }
  };

  return (
    <div className="min-h-screen bg-cream py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold mb-8">{i18n.TITLE[lang]}</h1>

        {
          !user ? (
            <div className="flex items-center justify-center min-h-[200px]">
              <div className="bg-base-200 rounded-lg p-8 text-center shadow-sm">
                <h3 className="text-xl font-medium text-base-content mb-2">{i18n.PLEASE_LOGIN[lang]}</h3>
                <p className="text-base-content/70">{i18n.LOGIN_TO_VIEW[lang]}</p>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
              {associations?.map((association) => (
                <AssociationCard
                  key={association.name}
                  association={association}
                  nAssociationClick={() => handleAssociationClick(association)}
                  lang={lang}
                />
              ))}
            </div>
          )
        }
      </div>
    </div>
  );
}

export async function guidanceLandingLoader() {
  const associations = await getClinicalAssociations();
  
  return associations || null;
}
