import React, { useEffect, useRef, useState } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import Masonry from 'react-masonry-css';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { getClinicalGuidanceTreeDetail, getClinicalGuidanceTrees, getThreadTasks } from '../api.js';
import { useAlert } from '../utils/context/alert.js';
import { useHistory } from '../utils/context/history';
import { useLanguage } from '../utils/context/lang.js';
import { useLayout } from '../utils/context/layout.js';

import EmptyState from './components/EmptyState.js';
import { Flow } from './components/TreeFlow.js';
import { i18n } from './i18n/guidance';

const PaperCard = ({ paper, onClick }) => {
  const { lang } = useLanguage();
  
  return (
    <div
      onClick={onClick}
      className="p-4 border-gray-100 rounded-lg overflow-hidden shadow-sm 
                 hover:shadow-lg transition-all duration-300 border bg-gradient-to-br from-gray-50 to-slate-50 
                 cursor-pointer flex flex-col justify-between text-gray-600"
    >
      <h3 className="mb-4 transition-colors text-lg font-semibold group-hover:text-gray-700 line-clamp-2">{paper.name}</h3>
      <div className="flex items-center gap-2 text-sm bg-slate-100 backdrop-blur-sm rounded-lg p-2 hover:bg-slate-200 transition-colors w-fit">
        <p>{i18n.VIEW_MORE[lang]}</p>
        <ArrowRightIcon className="w-4 h-4" />
      </div>
    </div>
  );
};

const breakpointColumns = {
  default: 3,
  1024: 3,
  640: 2
};

export function GuidanceDetail() {
  const { diseaseId, associationId, threadId: urlThreadId } = useParams();
  const { guidanceTrees, guidanceName, showGuidanceTreeDetail, guidanceTreeDetail, threadData: initialThreadData } = useLoaderData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ activeThreadId, setActiveThreadId ] = useState(urlThreadId);
  const [ showFlow, setShowFlow ] = useState(showGuidanceTreeDetail);
  const [ activeTree, setActiveTree ] = useState(guidanceTreeDetail);
  const [ threadData, setThreadData ] = useState(initialThreadData || {});
  const showAlert = useAlert();
  const containerRef = useRef(null);
  const { collapseSidebar } = useLayout();
  const { updateHistory } = useHistory();
  const { lang } = useLanguage();
  const navigate = useNavigate();

  const handleCardClick = async (treeId) => {
    setIsLoading(true);
    const result = await getClinicalGuidanceTreeDetail(associationId, diseaseId, treeId);
    setIsLoading(false);
    if (result?.id) {
      setActiveTree(result);
      setShowFlow(true);
      window.history.pushState(
        null,
        '',
        `/guidance/${associationId}/${diseaseId}/${treeId}`
      );
    } else {
      showAlert('error', '请求失败，请稍后再试');
    }
  };

  const handleBackButtonClick = () => {
    setActiveTree(null);
    setShowFlow(false);

    setThreadData({
      needFetchLatest: false,
      initialData: { results: [] },
      runningTask: null,
      threadId: null,
    });

    window.history.pushState(
      null, 
      '', 
      `/guidance/${associationId}/${diseaseId}/`
    );
  };

  // Sync with URL changes
  useEffect(() => {
    setActiveThreadId(urlThreadId);
  }, [ urlThreadId ]);

  useEffect(() => {
    setThreadData(initialThreadData);
  }, [ initialThreadData ]);

  useEffect(() => {
    setShowFlow(showGuidanceTreeDetail);
  }, [ showGuidanceTreeDetail ]);

  useEffect(() => {
    setActiveTree(guidanceTreeDetail);
  }, [ guidanceTreeDetail ]);

  // Handle responsive sidebar collapse
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth < 400) {
          collapseSidebar();
        }
      }
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={containerRef} className="relative min-h-screen bg-cream">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="loading loading-spinner loading-md text-primary-content"></div>
        </div>
      )}
      {
        showFlow ? <Flow
          threadData={threadData}
          treeData={activeTree}
          handleBackButtonClick={() => handleBackButtonClick()}
        /> :
          <>
            {/* Content Section */ }
            <div className="transition-all duration-300 ease-in-out mx-auto lg:mr-0">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                {guidanceTrees?.length === 0 ? (
                  <EmptyState />
                ) : (
                  <>
                    <div className="flex items-center relative justify-center gap-4 mb-8">
                      {/* Back button */}
                      <button
                        onClick={() => navigate(`/guidance/${associationId}/`)}
                        className="absolute top-[9px] left-0 group flex items-center gap-2 text-base-content/70 hover:text-base-content transition-colors duration-200"
                      >
                        <ArrowLeftIcon className="w-4 h-4" />
                        <span className="text-sm font-medium">{i18n.BACK[lang]}</span>
                      </button>
                      <h1 className="text-3xl font-bold text-center">
                        {associationId} {'>'} {guidanceName}
                      </h1>
                    </div>
                    <Masonry
                      breakpointCols={breakpointColumns}
                      className="flex -ml-4 w-auto"
                      columnClassName="pl-4 bg-clip-padding"
                    >
                      {guidanceTrees?.map((paper) => (
                        <div key={paper.name} className="mb-4">
                          <PaperCard
                            paper={paper}
                            onClick={() => handleCardClick(paper.name)}
                          />
                        </div>
                      ))}
                    </Masonry>
                  </>
                )}
              </div>
            </div>
          </>
      }
    </div >
  );
}

export async function guidanceDetailLoader({ params }) {
  const { associationId, diseaseId, treeId, threadId } = params;

  // const treesResult = await getClinicalGuidanceTrees(associationId, diseaseId);
  const [ treesResult, guidanceTreeDetail ] = await Promise.all([
    getClinicalGuidanceTrees(associationId, diseaseId),
    treeId ? getClinicalGuidanceTreeDetail(associationId, diseaseId, treeId) : ''
  ]);
  const guidanceTrees = treesResult?.[0]?.sub_trees || [];
  const guidanceName = treesResult?.[0]?.name || '';

  // If threadId exists, fetch thread data
  if (threadId) {
    const threadTasks = await getThreadTasks(threadId);

    const needFetchLatest =
      threadTasks?.results?.at(0) &&
      ![ 'complete', 'failed' ].includes(threadTasks?.results?.at(0)?.task_status);

    let runningTask = null;
    if (needFetchLatest) {
      runningTask = threadTasks.results.splice(0, 1)?.at(0);
    }
    threadTasks.results.reverse();

    return {
      guidanceTrees,
      guidanceName,
      guidanceTreeDetail,
      showGuidanceTreeDetail: treeId && guidanceTreeDetail?.id,
      threadData: {
        needFetchLatest,
        initialData: threadTasks,
        runningTask,
        threadId,
      },
    };
  }
  
  // Return just data if no threadId
  return {
    guidanceTrees,
    guidanceName,
    guidanceTreeDetail,
    showGuidanceTreeDetail: treeId && guidanceTreeDetail?.id,
    threadData: {
      needFetchLatest: false,
      initialData: { results: [] },
      runningTask: null,
      threadId: null,
    },
  };
}
