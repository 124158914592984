import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.min.mjs';

const debounce = (fn, delay) => {
  let timeoutId;
  const debouncedFn = (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
  
  // Add cancel method
  debouncedFn.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  
  return debouncedFn;
};

const PdfViewer = ({ url, searchText, onLoadStart, onLoadComplete, containerWidth }) => {
  const viewerRef = useRef(null);
  const scaleWrapperRef = useRef(null);
  const [ pdfBlob, setPdfBlob ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isResizing, setIsResizing ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ totalPages, setTotalPages ] = useState(0);
  const lastUrlRef = useRef(url);
  const currentWidthRef = useRef(containerWidth);

  // Extract page number from URL if present
  const getTargetPage = () => {
    try {
      const urlObj = new URL(url);
      const pageNum = urlObj.searchParams.get('page_num');
      return pageNum ? parseInt(pageNum) : 1;
    } catch (e) {
      return 1;
    }
  };

  // Scroll to specific page
  const scrollToPage = (pageNum) => {
    const pageElement = document.getElementById(`page-${pageNum}`);
    if (pageElement) {
      pageElement.scrollIntoView({ block: 'start', behavior: 'instant' });
    }
  };

  // Handle URL changes
  useEffect(() => {
    const currentUrl = new URL(url);
    const prevUrl = new URL(lastUrlRef.current);
    lastUrlRef.current = url;

    // If only page_num changed, just scroll
    if (currentUrl.origin === prevUrl.origin && 
        currentUrl.pathname === prevUrl.pathname && 
        currentUrl.searchParams.get('page_num') !== prevUrl.searchParams.get('page_num')) {
      scrollToPage(getTargetPage());
      return;
    }

    // If URL actually changed, fetch new PDF
    const fetchPdf = async () => {
      try {
        setIsLoading(true);
        onLoadStart?.();
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch PDF');
        const blob = await response.blob();
        setPdfBlob(blob);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdf();
  }, [ url ]);

  useEffect(() => {
    const renderPdf = async () => {
      if (!pdfBlob) return;

      try {
        setIsLoading(true);
        const container = viewerRef.current;
        container.innerHTML = '';

        // Create scale wrapper
        const scaleWrapper = document.createElement('div');
        scaleWrapper.className = 'scale-wrapper';
        scaleWrapper.style.width = '800px'; // Fixed base width
        scaleWrapper.style.transformOrigin = 'top center';
        scaleWrapper.style.transition = 'transform 0.2s ease';
        scaleWrapperRef.current = scaleWrapper;
        container.appendChild(scaleWrapper);

        // Create pages container
        const pagesContainer = document.createElement('div');
        pagesContainer.className = 'pages-container';
        scaleWrapper.appendChild(pagesContainer);

        const scale = 2; // Fixed base rendering scale

        const fileUrl = URL.createObjectURL(pdfBlob);
        const loadingTask = pdfjsLib.getDocument(fileUrl);
        const pdf = await loadingTask.promise;
        const pages = pdf.numPages;
        setTotalPages(pages);

        // Render all pages
        for (let i = 0; i < pages; i++) {
          const pageNum = i + 1;
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale });

          const pageContainer = document.createElement('div');
          pageContainer.className = 'page mb-4';
          pageContainer.style.position = 'relative';
          pageContainer.style.width = `${viewport.width / scale}px`;
          pageContainer.style.height = `${viewport.height / scale}px`;
          pageContainer.id = `page-${pageNum}`;

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          canvas.width = viewport.width;
          canvas.height = viewport.height;
          canvas.style.width = `${viewport.width / scale}px`;
          canvas.style.height = `${viewport.height / scale}px`;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
          pageContainer.appendChild(canvas);
          pagesContainer.appendChild(pageContainer);

          const pageNumDiv = document.createElement('div');
          pageNumDiv.className = 'absolute bottom-2 right-2 px-2 py-1 bg-base-200 rounded text-xs';
          pageNumDiv.textContent = `Page ${pageNum} of ${pages}`;
          pageContainer.appendChild(pageNumDiv);
        }

        // Initial scroll to target page
        scrollToPage(getTargetPage());
        onLoadComplete?.();

        // Set initial scale
        const initialScale = currentWidthRef.current ? currentWidthRef.current / 800 : 1;
        scaleWrapper.style.transform = `scale(${initialScale})`;

      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    renderPdf();
  }, [ pdfBlob ]);

  // Save debounced function instance using useRef
  const debouncedRenderRef = useRef(null);

  // Update containerWidth in ref
  useEffect(() => {
    currentWidthRef.current = containerWidth;
  }, [ containerWidth ]);

  useEffect(() => {
    const updateScale = async () => {
      if (!(scaleWrapperRef.current && viewerRef.current)) return;
      
      const container = viewerRef.current;
      const wrapper = scaleWrapperRef.current;
      
      const scrollTop = container.scrollTop;
      const currentScale = wrapper.style.transform
        ? parseFloat(wrapper.style.transform.match(/scale\((.*?)\)/)[1])
        : 1;
      const newScale = currentWidthRef.current ? currentWidthRef.current / 800 : 1;
      
      const contentHeight = wrapper.scrollHeight * currentScale;
      const relativePosition = scrollTop / contentHeight;

      wrapper.style.transform = `scale(${newScale})`;
      
      // Wait for scale animation to complete (200ms) before updating scroll position and showing content
      setTimeout(() => {
        requestAnimationFrame(() => {
          const newContentHeight = wrapper.scrollHeight * newScale;
          const newScrollTop = newContentHeight * relativePosition;
          container.scrollTop = newScrollTop;
          setIsResizing(false);
        });
      }, 200);
    };

    // Create debounced version of updateScale
    debouncedRenderRef.current = debounce(updateScale, 100);

    return () => {
      debouncedRenderRef.current?.cancel();
    };
  }, []);

  // Update scale ratio
  useEffect(() => {
    if (!containerWidth || isLoading) return;
    setIsResizing(true);
    debouncedRenderRef.current?.();
  }, [ containerWidth ]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-full text-error">
        Error loading PDF: {error}
      </div>
    );
  }

  return (
    <div className="relative w-full">
      {(isResizing && !isLoading) && (
        <div className="absolute inset-0 bg-base-100/50 z-10 flex items-center justify-center">
          <div className="loading loading-spinner loading-md"></div>
        </div>
      )}
      <div
        ref={viewerRef}
        className={`pdf-viewer ${isResizing ? 'opacity-0 transition-opacity duration-200' : ''}`}
        style={{
          width: '100%',
          height: '100vh',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      />
    </div>
  );
};

export default PdfViewer;

