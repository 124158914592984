const PRO_SUFFIX = 'Sonnet';

export function getFinalAgent(useUpgrade, currentAgentType) {
  return currentAgentType;

  // const no_postfix_agents = [ 'mindsearch', 'rag', 'mindsearchrefer', 'mindsearchofficialsite', 'mindsearchv2', 'nccnsearch', 'mindsearchworkflowrefer' ];
  // if (no_postfix_agents.includes(currentAgentType)) {
  //   return currentAgentType;
  // }
  // return useUpgrade
  //   ? currentAgentType.endsWith(PRO_SUFFIX) ? currentAgentType : currentAgentType + PRO_SUFFIX
  //   : currentAgentType.endsWith(PRO_SUFFIX) ? currentAgentType.slice(0, -PRO_SUFFIX.length) : currentAgentType;
}
