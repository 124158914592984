const NEW_THREAD = {
  en: 'New Thread',
  zh: '新对话',
};

const HOME = {
  en: 'Home',
  zh: '首页',
};

const HISTORY = {
  en: 'History',
  zh: '历史记录',
};
  
const LOGOUT = {
  en: 'Log Out',
  zh: '登出',
};
  
const FEEDBACK = {
  en: 'Feedback',
  zh: '反馈',
};

const DISCOVER = {
  en: 'Industry News',
  zh: '行业新闻',
};

const WORKFLOW = {
  en: 'Workflow',
  zh: '数据检索',
};

const CLINICAL_TRIAL_RESULT = {
  en: 'Clinical Results',
  zh: '临床结果洞察',
};

const DRUG_COMPETE = {
  en: 'Drug Pipeline',
  zh: '药品竞争格局',
};

const LOGIN = {
  en: 'Log In',
  zh: '登录',
}

const REGISTER = {
  en: 'Register',
  zh: '注册',
}

const CONFERENCE = {
  en: 'Conference Insights',
  zh: '会议见解',
};

const GUIDANCE = {
  en: 'Clinical Guidance',
  zh: '临床指南',
};

const CATALYST = {
  en: 'Catalyst Events',
  zh: '催化剂事件',
};

export const i18n = {
  NEW_THREAD,
  HOME,
  HISTORY,
  LOGOUT,
  FEEDBACK,
  DISCOVER,
  WORKFLOW,
  CONFERENCE,
  LOGIN,
  REGISTER,
  CLINICAL_TRIAL_RESULT,
  DRUG_COMPETE,
  CATALYST,
  GUIDANCE,
}
