import React, { useEffect, useRef, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../utils/context/lang';

const itemType = {
  'clinical-result': {
    en: 'trials',
    zh: '临床试验',
  },
  'drug-compete': {
    en: 'drugs',
    zh: '药物',
  },
  'conference': {
    en: 'Papers',
    zh: '论文',
  },
  'catalyst': {
    en: 'Catalysts',
    zh: '催化剂',
  },
  'clinical-guideline': {
    en: 'treatment nodes',
    zh: '诊疗节点',
  }
}

const selectLimitText = {
  'clinical-result': {
    en: 'You can select up to 5 trials',
    zh: '您最多可以选择5个临床试验',
  },
  'drug-compete': {
    en: 'You can select up to 50 drugs',
    zh: '您最多可以选择50个药物',
  },
  'conference': {
    en: 'You can select up to 5 papers',
    zh: '您最多可以选择5篇论文',
  },
  'catalyst': {
    en: 'You can select up to 5 catalysts',
    zh: '您最多可以选择5个催化剂',
  }
}

const selectLimit = {
  'clinical-result': 5,
  'drug-compete': 50,
  'conference': 5,
  'catalyst': 5,
}

const SelectedItems = ({ items, onRemove, type = 'conference' }) => {
  const { lang } = useLanguage();
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ hasOverflow, setHasOverflow ] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const isOverflowing = containerRef.current.scrollHeight > 32;
        setHasOverflow(isOverflowing);
      }
    };

    checkOverflow();
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [ items ]);

  if (!items?.length) return null;

  return (
    <div className="px-4 py-3 border-b border-base-200">
      <div className="flex items-center justify-between mb-1 h-6">
        <h3 className="text-sm font-medium">{lang === 'zh' ? '已选择' : 'Selected'} {itemType[type][lang]}  - {items.length}{selectLimit[type] ? `/${selectLimit[type]} ` : ' '}</h3>
        {hasOverflow && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-1 hover:bg-base-200 rounded-lg transition-colors duration-200 tooltip tooltip-left"
          >
            {isExpanded ? (
              <ChevronUpIcon className="w-4 h-4 text-base-content/50" />
            ) : (
              <ChevronDownIcon className="w-4 h-4 text-base-content/50" />
            )}
          </button>
        )}
      </div>
      <div 
        ref={containerRef}
        className={`flex flex-wrap gap-2 overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-8'}`}
      >
        {items.map(item => (
          <div 
            key={item.id}
            className="group flex items-center gap-1 px-2 py-1 bg-base-200 rounded-full text-xs"
          >
            <span className="truncate max-w-[150px]">
              {item.title || item.name || item.official_title|| item.citation_title || item.drug_name || item.content}
            </span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onRemove(item);
              }}
              className="opacity-50 hover:opacity-100 p-1"
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectedItems;