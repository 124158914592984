import { useEffect, useRef, useState } from 'react';

import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import Cookies from 'js-cookie';
// import { WebSocket } from 'mock-socket';
import {
  Navigate,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  createThread,
  createThreadEmbedding,
  getConferenceList,
  getDiscoverList,
  getHotTopic,
  getThreadMeta,
  getThreadTasks,
  getWorkflowList,
} from '../api.js';
import { OneConversation } from '../AskPage/components/oneConversation.js';
import { OneRunningConversation } from '../AskPage/components/oneRunningConversation.js';
import SingleLineInput from '../AskPage/components/singleLineInput.js';
import TextInput from '../AskPage/components/textInput.js';
import { i18n } from '../AskPage/i18n/chat.js';
import ReferenceView from '../Common/ChatPanal/ReferenceView.js';
import ArticleCard from '../Common/horizonNews.js';
import { i18n as i18nCommon } from '../Common/i18n/common.js';
import { useAlert } from '../utils/context/alert.js';
import { useHistory } from '../utils/context/history.js';
import { useLanguage } from '../utils/context/lang.js';
import { useLayout } from '../utils/context/layout.js';
import { clearLatestMessage , useWebSocketContext } from '../utils/context/main-websocket.js';
import { usePro } from '../utils/context/pro.js';
import { useUser } from '../utils/context/user.js';
import { getFinalAgent } from '../utils/helpers.js';

const langMap = {
  en: 'EN',
  zh: 'CN',
  jp: 'JP',
};


export function GenDetail() {
  const navigate = useNavigate();
  const { isSocketOpen, latestMessage, sendMessage, clearLatestMessage } = useWebSocketContext();
  const showAlert = useAlert();
  const { showModal } = useUser();
  const location = useLocation();
  const { lang, selectLanguage } = useLanguage();
  const { answerLang, switchAnswerLang, isWebSearchOn, setWebSearch } = usePro();
  const [ followups, setFollowups ] = useState([]);
  const [ newgenId, setNewgenId ] = useState(null);
  const [ focusArea, setFocusArea ] = useState(null);
  const [ forceSwitchValue, setForceSwitchValue ] = useState(undefined);
  const [ reachLimit, setReachLimit ] = useState(false);
  const [ stockSymbol, setStockSymbol ] = useState('');
  const [ isValidSymbol, setIsValidSymbol ] = useState(false);
  const [ isGenerating, setIsGenerating ] = useState(false);
  const [ suggestions, setSuggestions ] = useState([]);
  const [ showSuggestions, setShowSuggestions ] = useState(false);
  const bottomRef = useRef(null);
  const chatContainerRef = useRef(null);
  const { collapseSidebar } = useLayout();

  const {
    needFetchLatest,
    initialData,
    runningTask,
    threadAgentType,
  } = useLoaderData();
  const [ autoScroll, setAutoScroll ] = useState(true);
  const [ latestQuestion, setLatestQuestion ] = useState(null);
  const [ isFollowupFetching, setIsFollowupFetching ] = useState(false);
  const [ isEmbedding, setIsEmbedding ] = useState(false);
  const [ runningAnswer, setRunningAnswer ] = useState(null);
  const { genId } = useParams(); // path: ask/genId

  const [ searchParams ] = useSearchParams(); // page intermit state, will be finalized as threadID

  // {"thread_name":"hello","agent":"mindsearchrefer","task":{"task_mode":"noahllm","question":"hello"},"context":{"reference":"workflow id", "reference_type":"workflow"}}
  const [ q, contextUUID, contextType ] = [
    searchParams.get('q'),
    searchParams.get('contextUUID'),
    searchParams.get('contextType'),
  ];
  const contextTitle = location.state?.title;
  const mockClient = useRef(null);
  const [ paddingBottom, setPaddingBottom ] = useState('pb-12');
  const latestgenIdRef = useRef(newgenId); // Create a ref for the newgenId state
  const { updateHistory } = useHistory();
  console.log('========genId', genId);
  // this register the query for fetch the latest conversation
  const threadId = newgenId || genId;


  // Update the ref every time newgenId changes
  useEffect(() => {
    latestgenIdRef.current = threadId;
  }, [ threadId ]);

  useEffect(() => {
    if (location.pathname === '/report/' || location.pathname === '/report') {
      // Redirect to the home page
      setNewgenId(null);
      setLatestQuestion(null);
      setFollowups([]);
      setRunningAnswer(null);
      setIsFollowupFetching(false);
      // alert('back');
    }
  }, [ location ]);

  useEffect(() => {
    clearLatestMessage();
    return () => clearLatestMessage(); // Also clear on unmount
  }, [ location.pathname ]);
  // this resolves the situation when the initialData is loading
  useEffect(() => {
    if (needFetchLatest) {
      setIsFollowupFetching(true);
      setLatestQuestion(runningTask); // latestQuestion could from the loader data
    } else {
      setIsFollowupFetching(false);
      setLatestQuestion(null);
    }
  }, [ needFetchLatest, genId ]);

  // when a new thread is generated, update url and fetch the data
  useEffect(() => {
    if (newgenId) {
      window.history.replaceState(null, '', `/report/${newgenId}`);
    }
  }, [ newgenId ]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;
    const handleScroll = (e) => {
      // console.log('scrolled!!!!');
      if (autoScroll) setAutoScroll(false);
    };
    container.addEventListener('wheel', handleScroll); // NOTE: we must use wheel event, as programatic scroll could also trigger scroll event.
    return () => container.removeEventListener('wheel', handleScroll);
  }, [ newgenId ]);

  // Auto-scroll functionality
  useEffect(() => {
    if (autoScroll) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      // setTimeout(() => {
      //   bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      // }, 300);
    }
  }, [ followups, latestQuestion, paddingBottom, runningAnswer, autoScroll ]);

  const currentAgentType =
    runningTask?.agent || initialData?.results[0]?.agent || threadAgentType; // this value won't change, as it comes from the initial data

  const onSubmit = (text, attachments, useUpgrade) => {
    console.log('ppppppppppppppp, im here');
    if (isFollowupFetching) {
      return;
    }
    console.log({ text, attachments, useUpgrade });
    if (newgenId || genId) {
      console.log('follow up');
      submitFollowupQuestion(text, attachments, useUpgrade);
    } else {
      console.log("create normally wouldn't be triggered here...");
    }
    // Enable auto-scroll and set new question flag when submitting
    setAutoScroll(true);
  };

  const originalList = initialData?.results || [];
  const conversationList = originalList.map((item, index) => {
    // this is for the reference at top
    const handleReferenceClick = (ref) => {
      // console.log(12312313)
      console.log('top reference click', ref);
      setIsReferenceVisible(true);
      setCurrentReference(ref);
    };
    return (
      <OneConversation
        key={'' + index}
        item={item}
        onTriggerNewQuestion={onSubmit}
        onReferenceClick={handleReferenceClick}
      />
    );
  });
  const followupList = followups.map((item, index) => {
    // this is also for the reference at top
    const handleReferenceClick = (ref) => {
      // console.log(12312313)
      console.log('top reference click', ref);
      setIsReferenceVisible(true);
      setCurrentReference(ref);
    };
    return (
      <OneConversation
        key={'' + item.id || item.task_id + index}
        item={item}
        onTriggerNewQuestion={onSubmit}
        onReferenceClick={handleReferenceClick}
      />
    );
  });

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (!latestMessage) return;

    if (!latestMessage.type || latestMessage.type === 'LIMIT') {
      showAlert('error', i18n.DAILY_LIMIT_ERROR[lang]);
      setIsFollowupFetching(false);
      setForceSwitchValue(false);
    } else if (latestMessage.type === 'CONTENT') {
      setRunningAnswer(latestMessage);

      if (latestMessage.status === 'DONE') {
        setIsFollowupFetching(false);
        setRunningAnswer(null);
        setFollowups((prev) => [ ...prev, latestMessage ]);
      }
    }
  }, [ latestMessage ]);

  // Reset states when location changes
  useEffect(() => {
    if (location.pathname === '/report/' || location.pathname === '/report') {
      setNewgenId(null);
      setLatestQuestion(null);
      setFollowups([]);
      setRunningAnswer(null);
      setIsFollowupFetching(false);
    }
  }, [ location ]);

  // Handle initial data loading
  useEffect(() => {
    if (needFetchLatest) {
      setIsFollowupFetching(true);
      setLatestQuestion(runningTask);
    } else {
      setIsFollowupFetching(false);
      setLatestQuestion(null);
    }
  }, [ needFetchLatest, genId ]);

  const createQuestion = async (text, attachments, useUpgrade, focusArea) => {
    if (!isSocketOpen || isFollowupFetching) {
      showAlert('error', i18n.CONNECTION_ERROR[lang]);
      return;
    }

    setFocusArea(focusArea);
    setLatestQuestion({
      question: 'Generate a comprehensive report for ' + text,
      upload_files: attachments,
      agent: focusArea,
    });
    setIsFollowupFetching(true);

    try {
      const newThreadId = await createThread(
        'Generate a comprehensive report for ' + text,
        getFinalAgent(useUpgrade, focusArea),
        'workflow',
        'investment_report',
      );
      setNewgenId(newThreadId);
      updateHistory();

      sendMessage({
        thread_id: newThreadId,
        question: text,
        upload_files: attachments?.map(a => a.id),
        agent: 'investment_report',
        enable_rag: isWebSearchOn,
        language: answerLang,
      });
    } catch (error) {
      setIsFollowupFetching(false);
      showAlert('error', error?.response?.data?.detail || error.message);
    }
  };

  const submitFollowupQuestion = async (text, attachments, useUpgrade) => {
    if (!isSocketOpen || isFollowupFetching) {
      showAlert('error', i18n.CONNECTION_ERROR[lang]);
      return;
    }

    setLatestQuestion({
      question: text,
      upload_files: attachments,
      agent: currentAgentType || focusArea,
    });
    setIsFollowupFetching(true);

    try {
      sendMessage({
        thread_id: threadId,
        question: text,
        upload_files: attachments?.map(a => a.id),
        agent: getFinalAgent(useUpgrade, currentAgentType || focusArea),
        language: answerLang,
      });
    } catch (error) {
      setIsFollowupFetching(false);
      showAlert('error', error?.response?.data?.detail || error.message);
    }
  };

  const [ isReferenceVisible, setIsReferenceVisible ] = useState(false);
  const [ currentReference, setCurrentReference ] = useState(null);

  // Add reference click handler
  // const handleReferenceClick = (ref) => {
  //   // console.log(12312313)
  //   setIsReferenceVisible(true);
  //   setCurrentReference(ref);
  // };

  // Handle responsive sidebar collapse
  useEffect(() => {
    const handleResize = () => {
      // IMPORTANT: manually collapse the sidebar won't trigger the resize event, which is FANTASTIC!
      if (chatContainerRef.current) {
        const containerWidth = chatContainerRef.current.offsetWidth;
        if (containerWidth < 400) {
          collapseSidebar();
        }
      }
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGenerateReport = (symbol) => {
    setIsGenerating(true);
    window.gtag('event', 'form', {
      event_category: 'gen_create',
      event_label: 'direct',
      focus_area: 'investment_report',
      pro: true,
      text_input: `Generate a comprehensive report for ${symbol} stock`,
    });
    
    createQuestion(
      symbol,
      [],
      true,
      'investment_report'
    );
  };

  // Mock data for pharma stock symbols
  const pharmaStocks = [
    { symbol: 'PFE', name: 'Pfizer Inc.' },
    { symbol: 'JNJ', name: 'Johnson & Johnson' },
    { symbol: 'MRK', name: 'Merck & Co., Inc.' },
    { symbol: 'ABBV', name: 'AbbVie Inc.' },
    { symbol: 'LLY', name: 'Eli Lilly and Company' },
    { symbol: 'BMY', name: 'Bristol-Myers Squibb Company' },
    { symbol: 'AMGN', name: 'Amgen Inc.' },
    { symbol: 'GSK', name: 'GlaxoSmithKline plc' },
    { symbol: 'NVS', name: 'Novartis AG' },
    { symbol: 'AZN', name: 'AstraZeneca PLC' },
    { symbol: 'GILD', name: 'Gilead Sciences, Inc.' },
    { symbol: 'BIIB', name: 'Biogen Inc.' },
    { symbol: 'VRTX', name: 'Vertex Pharmaceuticals Incorporated' },
    { symbol: 'REGN', name: 'Regeneron Pharmaceuticals, Inc.' },
    { symbol: 'MRNA', name: 'Moderna, Inc.' },
  ];

  if (!genId && !newgenId && !latestQuestion && !q) {
    return (
      <div className="flex flex-col justify-center min-h-[800px] h-screen bg-cream">
        <div className="max-w-4xl mx-auto w-full px-4 py-12">
          <h1 className="text-center text-3xl font-bold mb-2">Pharma Analysis</h1>
          <p className="text-center text-gray-500 mb-12">Get comprehensive analysis and insights for any pharmaceutical company</p>
          
          <div className="bg-white rounded-xl shadow-lg p-8 transition-all hover:shadow-xl">
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4">Enter Pharma Stock Symbol</h2>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 3a1 1 0 000 2h10a1 1 0 100-2H3zm0 4a1 1 0 000 2h6a1 1 0 100-2H3zm0 4a1 1 0 100 2h4a1 1 0 100-2H3z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <input 
                      type="text" 
                      id="stockSymbol" 
                      className={`block w-full pl-10 pr-3 py-3 border ${isValidSymbol || !stockSymbol ? 'border-gray-300' : 'border-red-500'} rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg`}
                      placeholder="Enter pharma symbol (e.g., PFE, JNJ, MRK)"
                      value={stockSymbol}
                      onChange={(e) => {
                        const value = e.target.value.toUpperCase().trim();
                        setStockSymbol(value);
                        // Simple validation: stock symbols are typically 1-5 uppercase letters
                        setIsValidSymbol(/^[A-Z]{1,5}$/.test(value));
                        
                        // Filter suggestions based on input
                        if (value) {
                          const filtered = pharmaStocks.filter(
                            stock => stock.symbol.includes(value) || 
                                    stock.name.toUpperCase().includes(value)
                          );
                          setSuggestions(filtered.slice(0, 5)); // Limit to 5 suggestions
                          setShowSuggestions(true);
                        } else {
                          setSuggestions([]);
                          setShowSuggestions(false);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && isValidSymbol && stockSymbol) {
                          e.preventDefault();
                          handleGenerateReport(stockSymbol);
                          setShowSuggestions(false);
                        } else if (e.key === 'Escape') {
                          setShowSuggestions(false);
                        }
                      }}
                      onBlur={() => {
                        // Delay hiding suggestions to allow for clicks
                        setTimeout(() => setShowSuggestions(false), 200);
                      }}
                      onFocus={() => {
                        if (stockSymbol && suggestions.length > 0) {
                          setShowSuggestions(true);
                        }
                      }}
                    />
                    {showSuggestions && suggestions.length > 0 && (
                      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                        {suggestions.map((stock) => (
                          <div
                            key={stock.symbol}
                            className="px-4 py-2 hover:bg-blue-50 cursor-pointer flex justify-between items-center"
                            onClick={() => {
                              setStockSymbol(stock.symbol);
                              setIsValidSymbol(true);
                              setShowSuggestions(false);
                            }}
                          >
                            <span className="font-medium">{stock.symbol}</span>
                            <span className="text-gray-500 text-sm">{stock.name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    {stockSymbol && !isValidSymbol && (
                      <p className="text-red-500 text-sm mt-1">Please enter a valid stock symbol (1-5 letters)</p>
                    )}
                  </div>
                </div>
                <button 
                  className={`${isValidSymbol && stockSymbol ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'} text-white font-medium py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center`}
                  disabled={!isValidSymbol || !stockSymbol || isGenerating}
                  onClick={() => {
                    if (isValidSymbol && stockSymbol) {
                      handleGenerateReport(stockSymbol);
                    }
                  }}
                >
                  {isGenerating ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Generating...
                    </>
                  ) : (
                    'Generate Report'
                  )}
                </button>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <div className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                <div className="flex items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                  </svg>
                  <h3 className="font-medium text-gray-700">Financial Performance</h3>
                </div>
                <p className="text-sm text-gray-500">Revenue, R&D spending, profit margins, and drug sales metrics</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                <div className="flex items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <h3 className="font-medium text-gray-700">Drug Pipeline</h3>
                </div>
                <p className="text-sm text-gray-500">Clinical trials, FDA approvals, and upcoming drug releases</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                <div className="flex items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                  </svg>
                  <h3 className="font-medium text-gray-700">Market Outlook</h3>
                </div>
                <p className="text-sm text-gray-500">Patent expirations, competitive landscape, and growth potential</p>
              </div>
            </div>
            
            <div className="text-sm text-gray-500 mt-4 bg-blue-50 p-4 rounded-lg border border-blue-100">
              <div className="flex items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                <span className="font-medium text-blue-700">AI-Powered Pharma Analysis</span>
              </div>
              <p className="text-blue-600">Our AI-powered analysis provides up-to-date insights on pharmaceutical companies, including drug pipelines, clinical trials, and market positioning.</p>
            </div>
          </div>
          
          {/* <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="font-semibold mb-4 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
                Popular Pharma Stocks
              </h3>
              <div className="flex flex-wrap gap-2">
                {[ 'PFE', 'JNJ', 'MRK', 'ABBV', 'LLY', 'BMY', 'AMGN', 'GSK', 'NVS', 'AZN' ].map((symbol) => (
                  <button 
                    key={symbol}
                    className="px-3 py-1 bg-gray-100 hover:bg-gray-200 rounded-full text-sm transition-colors duration-200"
                    onClick={() => {
                      setStockSymbol(symbol);
                      setIsValidSymbol(true);
                      handleGenerateReport(symbol);
                    }}
                  >
                    {symbol}
                  </button>
                ))}
              </div>
            </div>
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="font-semibold mb-4 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                </svg>
                Report Options
              </h3>
              <div className="space-y-3">
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="includeClinicalTrials" 
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    defaultChecked={true}
                  />
                  <label htmlFor="includeClinicalTrials" className="ml-2 text-sm text-gray-700">Include clinical trial data</label>
                </div>
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="includePatents" 
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    defaultChecked={true}
                  />
                  <label htmlFor="includePatents" className="ml-2 text-sm text-gray-700">Include patent information</label>
                </div>
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="includeRegulatory" 
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    defaultChecked={true}
                  />
                  <label htmlFor="includeRegulatory" className="ml-2 text-sm text-gray-700">Include regulatory updates</label>
                </div>
              </div>
            </div>
          </div> */}
          
          {/* <div className="mt-6 bg-white rounded-lg shadow p-6">
            <h3 className="font-semibold mb-4 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clipRule="evenodd" />
              </svg>
              Pharma Industry Trends
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="border border-gray-200 rounded-lg p-3">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-medium">Pharma Index</span>
                  <span className="text-green-600 font-medium">+1.2%</span>
                </div>
                <div className="h-10 bg-gray-100 rounded relative overflow-hidden">
                  <div className="absolute bottom-0 left-0 h-full w-full bg-gradient-to-r from-blue-100 to-blue-50"></div>
                  <svg className="relative z-10 h-full w-full" viewBox="0 0 100 20" preserveAspectRatio="none">
                    <path d="M0,10 L10,12 L20,8 L30,14 L40,6 L50,10 L60,7 L70,12 L80,9 L90,13 L100,10" stroke="rgb(59, 130, 246)" strokeWidth="1.5" fill="none" />
                  </svg>
                </div>
              </div>
              <div className="border border-gray-200 rounded-lg p-3">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-medium">Biotech Sector</span>
                  <span className="text-green-600 font-medium">+2.5%</span>
                </div>
                <div className="h-10 bg-gray-100 rounded relative overflow-hidden">
                  <div className="absolute bottom-0 left-0 h-full w-full bg-gradient-to-r from-blue-100 to-blue-50"></div>
                  <svg className="relative z-10 h-full w-full" viewBox="0 0 100 20" preserveAspectRatio="none">
                    <path d="M0,12 L10,10 L20,13 L30,9 L40,14 L50,8 L60,11 L70,7 L80,10 L90,6 L100,9" stroke="rgb(59, 130, 246)" strokeWidth="1.5" fill="none" />
                  </svg>
                </div>
              </div>
              <div className="border border-gray-200 rounded-lg p-3">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-medium">Healthcare ETF</span>
                  <span className="text-red-600 font-medium">-0.3%</span>
                </div>
                <div className="h-10 bg-gray-100 rounded relative overflow-hidden">
                  <div className="absolute bottom-0 left-0 h-full w-full bg-gradient-to-r from-blue-100 to-blue-50"></div>
                  <svg className="relative z-10 h-full w-full" viewBox="0 0 100 20" preserveAspectRatio="none">
                    <path d="M0,8 L10,10 L20,7 L30,9 L40,11 L50,8 L60,12 L70,10 L80,13 L90,11 L100,14" stroke="rgb(59, 130, 246)" strokeWidth="1.5" fill="none" />
                  </svg>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
  return (
    // isSocketOpen &&
    <div
      className={`relative h-screen ${
        isReferenceVisible &&
        currentReference?.url?.toLowerCase().includes('.pdf')
          ? 'lg:mr-[800px]'
          : isReferenceVisible
            ? 'lg:mr-[400px]'
            : 'lg:mr-0'
      }`}
    >
      {/* <Slider>
        <PdfViewer url={'/pdf/US-KOL-Wet_AMD_WA6.pdf'} pageNumber={4} />
      </Slider> */}
      <div
        className={`absolute bg-cream w-full h-full flex-1 overflow-y-scroll ${paddingBottom}`}
        ref={chatContainerRef}
      >
        {/* {contextType && <p>{agent_i18n["mindsearchrefer"][lang]}</p>}
        {threadAgentType && <p>{agent_i18n[threadAgentType][lang]}</p>} */}
        <div className="px-12 lg:px-16 mx-auto text-base leading-7 text-secondary-content mt-12">
          {/* {answerSection} */}
          {/* <HelloMsg agentType={currentAgentType} onSubmit={onSubmit} /> */}
          {location.state?.sourceURL && (
            <div
              className="max-w-5xl mb-4 mx-auto"
              onClick={() => {
                window.open(location.state?.sourceURL, '_blank');
              }}
            >
              <div className="cursor-pointer bg-base-300 rounded-lg p-4 mx-auto">
                <div className="mr-2 flex text-md items-center">
                  <ViewfinderCircleIcon className="w-4 h-4 mr-2" />
                  {i18nCommon.CONTEXT_TITLE[lang]}
                </div>
                <p className="text-lg font-bold">{location.state?.title}</p>
              </div>
            </div>
          )}

          {conversationList}
          {followupList}
          {isFollowupFetching && (
            <OneRunningConversation
              item={latestQuestion}
              runningAnswer={runningAnswer}
              isEmbedding={isEmbedding}
            />
          )}
          <div ref={bottomRef} className="h-6"></div>
        </div>
      </div>

      {/* Philosophy: detail is just like chatpanal without reference, so it needs a referenceView TODO: ziwen*/}
      {/* Reference Panel with Animation */}
      <AnimatePresence>
        {isReferenceVisible && currentReference && (
          <motion.div
            initial={{ x: 400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 400, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-0 right-0 h-screen z-40 border-l border-base-200"
          >
            <ReferenceView
              reference={currentReference}
              isChatVisible={true}
              onClose={() => {
                setIsReferenceVisible(false);
                setCurrentReference(null);
              }}
              onOpenChat={() => {}}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <SingleLineInput
        className="absolute bottom-3 md:w-3/4 lg:w-2/3 w-full mx-auto left-0 right-0"
        onSubmit={onSubmit}
        // enableUpload={
        //   agents.filter((agent) => agent.category === currentAgentType).at(0)
        //     .enableUpload
        // }
        enableUpload={true}
        disabled={isFollowupFetching}
        onFileCountChange={(count) => {
          if (count > 0) {
            setPaddingBottom('pb-32');
          } else {
            setPaddingBottom('pb-12');
          }
        }}
      />
    </div>
  );
}

export async function genDetailLoader({ params }) {
  let runningTask = {};
  if (!params.genId) {
    return {
      needFetchLatest: false,
      initialData: null,
      runningTask: null,
      threadAgentType: null,
    };
  }
  const initialData = await getThreadTasks(params.genId);
  const thread = await getThreadMeta(params.genId);
  const needFetchLatest =
    initialData?.results?.at(0) &&
    ![ 'complete', 'failed' ].includes(initialData?.results?.at(0)?.task_status);
  if (needFetchLatest) {
    runningTask = initialData.results.splice(0, 1)?.at(0);
  }
  initialData?.results?.reverse();
  // console.log("===", {needFetchLatest,initialData});
  return {
    needFetchLatest,
    initialData,
    runningTask,
    threadAgentType: thread?.agent,
  };
}
