import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline';
import {
  addEdge,
  Background,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import { Input } from 'antd';
import ELK from 'elkjs/lib/elk.bundled.js';
import { useParams } from 'react-router-dom';

import ChatPanel from '../../Common/ChatPanal';
import { i18n as chatI18n } from '../../Common/i18n/chat';
import { useAlert } from '../../utils/context/alert';
import { useHistory } from '../../utils/context/history';
import { useLanguage } from '../../utils/context/lang.js';
import { i18n } from '../i18n/guidance';

import CustomNode from './CustomNode'
import DetailModal from './DetailModal';
 
import '@xyflow/react/dist/style.css';
import '../styles/flow.css';

const elk = new ELK();
 
// Elk has a *huge* amount of options to configure. To see everything you can
// tweak check out:
//
// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html
const elkOptions = {
  'elk.direction': 'DOWN',
  'elk.algorithm': 'layered',
  'elk.layered.spacing.nodeNodeBetweenLayers': '60',
  'elk.spacing.nodeNode': '80',
};

// Function to calculate node dimensions
const calculateNodeDimensions = (node) => {
  if (node.type === 'custom') {
    // Calculate possible number of lines for text
    const width = Math.min(node?.data?.content?.length * 12, 364); 
    const contentLines = Math.ceil(node?.data?.content?.length * 12 / width);
    
    // Calculate height based on line count
    // Each line is about 24px high, plus padding and other elements
    const textHeight = (contentLines) * 24;
    
    return {
      width: width + 36,
      height: textHeight + 65
    };
  }

  return {
    width: Math.min(Math.max(node?.data?.content?.length * 12, 100), 264),
    height: 50
  };
};
 
const getLayoutedElements = (nodes, edges, options = {}) => {
  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes.map((node) => {
      const { width, height } = node?.width ? node : calculateNodeDimensions(node);
      return {
        ...node,
        targetPosition: 'top',
        sourcePosition: 'bottom',
        width,
        height,
      };
    }),
    edges: edges,
  };
 
  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        // React Flow expects a position property on the node instead of `x`
        // and `y` fields.
        position: { x: node.x, y: node.y },
      })),
 
      edges: layoutedGraph.edges,
    }))
    .catch(console.error);
};

function LayoutFlow({ threadData, originalEdges, originalNodes, pdfUrl, followupQuestions, treeName, referenceId, source, handleBackButtonClick }) {
  const [ nodes, setNodes, onNodesChange ] = useNodesState([]);
  const [ edges, setEdges, onEdgesChange ] = useEdgesState([]);
  const { threadId: urlThreadId } = useParams();
  const { lang } = useLanguage();
  const [ selectList, setSelectList ] = useState([]);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isPanelOpen, setIsPanelOpen ] = useState(true);
  const [ activeThreadId, setActiveThreadId ] = useState(urlThreadId);
  const [ modalContent, setModalContent ] = useState('');
  const [ selectedReference, setSelectedReference ] = useState(null);
  const [ pendingHeightUpdates, setPendingHeightUpdates ] = useState({});
  const [ searchKeywords, setSearchKeywords ] = useState('');
  const [ searchResult, setSearchResult ] = useState(null);
  const [ selectedResult, setSelectedResult ] = useState([]);
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ showSearchCount, setShowSearchCount ] = useState(false);
  const showAlert = useAlert();
  const { updateHistory } = useHistory();
  const hasLayout = useRef(false);
  const { fitView, setViewport, getViewport } = useReactFlow();
  const nodeTypes = {
    custom: CustomNode,
  };

  const initialEdges = useMemo(() => originalEdges?.map(edge => ({
    ...edge,
    style: {
      ...edge?.style,
      strokeWidth: 2,
      opacity: 0.8,
    },
  })), [ originalEdges ]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const toggleAssetSelect = (asset) => {
    setSelectList(prev => {
      const isSelected = prev.some(p => p.id === asset.id);
      if (isSelected) {
        return prev.filter(p => p.id !== asset.id);
      } else {
        return [ ...prev, asset ];
      }
    });
  };

  const handleSelectAll = (selectAll) => {    
    const itemsToSelect = selectAll ? initialNodes?.map(node => node.data) : [];
    setSelectList(itemsToSelect);
  };

  const handleReferenceClick = (page_num = 1) => {
    setIsPanelOpen(true);
    setSelectedReference({
      sources: [
        {
          url: `${pdfUrl}?page_num=${page_num}`,
        }
      ],
      referenceIndex: 0
    });
  };

  // Handle thread creation
  const handleThreadCreated = (newThreadId) => {
    // NOTE: we should not set activeThreadId here, because it will trigger the chat panel to reset.
    window.history.replaceState(
      null, 
      '', 
      `/guidance/${source}/${referenceId}/${treeName}/chat/${newThreadId}`
    );
    setActiveThreadId(newThreadId);
    updateHistory();
  };

  const handleNodeHeightChange = useCallback(({ nodeHeight, contentHeight, id, height }) => {
    setPendingHeightUpdates(prev => ({
      ...prev,
      [id]: height
    }));
  }, []);

  // Batch update node height
  useEffect(() => {
    if (Object.keys(pendingHeightUpdates).length > 0) {
      setNodes(nodes => 
        nodes.map(node => {
          const newHeight = pendingHeightUpdates[node.id];
          if (newHeight) {
            return {
              ...node,
              height: newHeight
            };
          }
          return node;
        })
      );
      setPendingHeightUpdates({}); // Clear the pending update queue
    }
  }, [ pendingHeightUpdates, setNodes ]);

  const layoutElements = useCallback(async (nodes, edges) => {
    if (!nodes?.length) return null;
    
    const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(
      nodes,
      edges,
      elkOptions
    );
    
    return {
      layoutedNodes,
      layoutedEdges
    };
  }, []);

  // Get all child node IDs - using iteration instead of recursion
  const getChildNodes = (nodeId) => {
    const childNodes = new Set();
    const queue = [ nodeId ];
    const visited = new Set();

    while (queue.length > 0) {
      const currentId = queue.shift();
      
      if (visited.has(currentId)) {
        continue;
      }
      
      visited.add(currentId);

      initialEdges.forEach(edge => {
        if (edge.source === currentId && edge.target !== nodeId) {
          childNodes.add(edge.target);
          queue.push(edge.target);
        }
      });
    }

    return Array.from(childNodes);
  };

  const searchKeywordsRef = useRef('');

  useEffect(() => {
    searchKeywordsRef.current = searchKeywords;
  }, [ searchKeywords ]);

  const foucusElement = (node, needHighlight = false, maxZoom = 0.8) => {
    if (node) {
      setTimeout(() => {
        fitView({
          duration: 800,
          padding: 0.1,
          minZoom: 0.4,
          maxZoom,
          nodes: [ node ],
          includeHiddenNodes: false,
          zoom: 0.45,
          direction: 'down',
        });
      }, 50);
    }

    // Highlight selected node (commented out)
    if (needHighlight) {
      setNodes(nds => 
        nds.map(item => {
          const isFoucused = item.id === node?.id;
          return {
            ...item,
            data: {
              ...item.data,
              isFoucused
            }
          };
        })
      );
    }
  };

  const handleSearch = useCallback((needFoucus = false) => {
    setNodes(nds => 
      nds.map(item => {
        return {
          ...item,
          data: {
            ...item.data,
            isFoucused: false,
          }
        };
      })
    );
    if (!searchKeywordsRef.current) return;
    
    const currentNodes = nodesRef.current;
    const result = currentNodes.filter(node => 
      node.data.content.toLowerCase().includes(searchKeywordsRef.current.toLowerCase()) && 
      node.className !== 'flow-hidden'
    );
    
    setSearchResult(result);
    setShowSearchCount(true);
    
    if (result.length === 0) {
      showAlert({
        type: 'warning',
        message: i18n.NO_SEARCH_RESULT[lang]
      });
      setSelectedResult(null);
      setSelectedIndex(-1);
      return;
    } else {
      setSelectedResult(result[0]);
      setSelectedIndex(0);
      needFoucus && foucusElement(result[0], true);
    }
  }, []);

  // Handle node collapse/expand
  const handleToggleCollapse = useCallback((nodeId) => {
    // 1. Update collapse state and get affected nodes
    setNodes(nds => {
      const updatedNodes = nds.map(node => {
        if (node.id === nodeId) {
          const newCollapsedState = !node.data.isCollapsedTree;
          return {
            ...node,
            data: {
              ...node.data,
              isCollapsedTree: newCollapsedState
            }
          };
        }
        return node;
      });

      const childIds = getChildNodes(nodeId);
      const targetNode = updatedNodes.find(n => n.id === nodeId);
      const isCollapsing = targetNode.data.isCollapsedTree;

      // 2. Update node classname and visibility
      const nodesWithUpdatedVisibility = updatedNodes.map(node => ({
        ...node,
        data: {
          ...node.data,
          isCollapsedTree: childIds.includes(node.id) ? false : node.data.isCollapsedTree
        },
        className: childIds.includes(node.id) ? isCollapsing ? 'flow-hidden' : '' : (node.className || ''),
      }));

      // 3. Sync edge visibility
      const visibleNodeIds = nodesWithUpdatedVisibility
        .filter(node => !node.className?.includes('flow-hidden'))
        .map(node => node.id);

      // 4. Update edge display state
      setEdges(currentEdges => 
        currentEdges.map(edge => ({
          ...edge,
          className: (!visibleNodeIds.includes(edge.source) || !visibleNodeIds.includes(edge.target)) 
            ? 'flow-hidden' 
            : '',
        }))
      );

      // Update nodesRef to ensure latest state for search
      nodesRef.current = updatedNodes;
      
      return nodesWithUpdatedVisibility;
    });

    // Use Promise.resolve().then to ensure search executes after state update
    Promise.resolve().then(() => {
      if (searchKeywordsRef.current) {
        handleSearch(false);
      }
    });
  }, [ handleSearch ]);

  const nodesRef = useRef();

  useEffect(() => {
    nodesRef.current = nodes;
  }, [ nodes ]);

  const initialNodes = useMemo(() => {
    return originalNodes?.map(node => {
      // Check if node has children
      const hasChildren = originalEdges?.some(edge => edge.source === node.id);
      
      return {
        ...node,
        data: {
          ...node.data,
          selectList,
          onItemClick: toggleAssetSelect,
          onExclamationClick: handleModalOpen,
          onHeightChange: handleNodeHeightChange,
          openPdf: handleReferenceClick,
          hasChildren,
          isCollapsedTree: false,
          onToggleCollapse: handleToggleCollapse
        }
      };
    });
  }, [ originalNodes, selectList, originalEdges, handleToggleCollapse ]);

  useLayoutEffect(() => {
    if (initialNodes?.length > 0 && !hasLayout.current) {
      hasLayout.current = true;
      
      (async () => {
        const layout = await layoutElements(initialNodes, initialEdges);
        if (layout) {
          setNodes(layout.layoutedNodes);
          setEdges(layout.layoutedEdges);
          
          // Find and focus on root node
          const rootNode = layout.layoutedNodes.find(node => node.id === '1');
          foucusElement(rootNode, false, 0.5);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (!selectList || !nodes.length) return;
    
    setNodes(nds => 
      nds.map(node => ({
        ...node,
        data: {
          ...node.data,
          selectList
        }
      }))
    );
  }, [ selectList, setNodes, nodes.length ]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [],
  );

  const handleMinimapClick = useCallback((event, position) => {
    event.preventDefault();
    event.stopPropagation();

    const viewport = getViewport();
    const zoom = viewport.zoom || 1;

    // Calculate the click position relative to the view
    setViewport(
      {
        x: -position.x * zoom + window.innerWidth / 2,
        y: -position.y * zoom + window.innerHeight / 2,
        zoom,
      },
      { duration: 800 } // Add animation effect
    );
  }, [ getViewport, setViewport ]);
 
  return (
    <>
      <div 
        className={`xs:w-full mx-auto px-4 md:px-8 z-10 pb-8 transition-all duration-300 ease-in-out ${isPanelOpen ? 'lg:mr-[666px]' : 'lg:mr-0'}`}
      >
        <div className="sticky top-0 z-30 flex flex-wrap items-center justify-between gap-4 py-6">
          {/* Back button */}
          <button
            onClick={() => handleBackButtonClick()}
            className="group flex items-center gap-2 text-base-content/70 hover:text-base-content transition-colors duration-200"
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="text-sm font-medium">{i18n.BACK[lang]}</span>
          </button>
          {/* View Toggle */}
          <div className="flex items-center gap-2">
            <Input
              placeholder={i18n.SEARCH_PLACEHOLDER[lang]}
              style={{ minWidth: '120px' }}
              value={searchKeywords}
              onChange={(e) => {                
                setShowSearchCount(false);
                setSearchKeywords(e.target.value);
              }}
              onPressEnter={() => handleSearch(true)}
              suffix={
                <div className="flex items-center gap-1">
                  <div className="border-r border-base-200 pr-2 flex justify-center text-base-content/60">
                    {showSearchCount ? `${selectedIndex + 1}/${searchResult.length}` : '\u00A0'}
                  </div>
                  <button
                    type="button"
                    className="rounded-full disabled:bg-base-100 disabled:text-zinc-300 p-1.5 hover:bg-base-200 transition-colors duration-200"
                    onClick={() => {
                      setSelectedIndex(selectedIndex - 1);
                      setSelectedResult(searchResult[selectedIndex - 1]);
                      foucusElement(searchResult[selectedIndex - 1], true);
                    }}
                    disabled={selectedIndex <= 0 || !showSearchCount}
                  >
                    <ChevronUpIcon aria-hidden="true" className="h-4 w-4" />
                  </button>
                  <button
                    type="button"
                    className="rounded-full disabled:bg-base-100 disabled:text-zinc-300 p-1.5 hover:bg-base-200 transition-colors duration-200"
                    onClick={() => {
                      setSelectedIndex(selectedIndex + 1);
                      setSelectedResult(searchResult[selectedIndex + 1]);
                      foucusElement(searchResult[selectedIndex + 1], true);
                    }}
                    disabled={(selectedIndex === searchResult?.length - 1 || !searchResult) || !showSearchCount}
                  >
                    <ChevronDownIcon aria-hidden="true" className="h-4 w-4" />
                  </button>
                  <button
                    type="button"
                    className="rounded-full disabled:bg-base-100 disabled:text-zinc-300 p-1.5 hover:bg-base-200 transition-colors duration-200"
                    onClick={() => handleSearch(true)}
                    disabled={searchKeywords.length === 0}
                  >
                    <MagnifyingGlassIcon aria-hidden="true" className="h-4 w-4" />
                  </button>
                </div>
              }
            />
            {/* Select/Deselect All Buttons */}
            {/* <div className="flex items-center bg-base-100/50 backdrop-blur-sm 
                    border border-base-200 rounded-full p-1 shadow-sm mr-2">
              <button
                onClick={() => handleSelectAll(true)}
                className="flex-1 px-3 py-1.5 rounded-full text-sm font-medium text-base-content/60 
                        hover:text-base-content hover:bg-base-200/50 transition-all duration-200 whitespace-nowrap"
              >
                {chatI18n.SELECT_ALL[lang]}
              </button>
              <button
                onClick={() => handleSelectAll(false)}
                className="flex-1 px-3 py-1.5 rounded-full text-sm font-medium text-base-content/60 
                        hover:text-base-content hover:bg-base-200/50 transition-all duration-200 whitespace-nowrap"
              >
                {chatI18n.DESELECT_ALL[lang]}
              </button>
            </div> */}
          </div>
        </div>

        <div style={{ height: 'calc(100vh - 125px)', minHeight: '600px' }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            minZoom={0.3}
            fitView
            nodeTypes={nodeTypes}
            fitViewOptions={{}}
            className="rounded-xl bg-base-200/20"
            style={{
              transition: 'all 0.3s ease-in-out',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)'
            }}
            nodesDraggable={false} // Disable node dragging
            nodesConnectable={false} // Disable node connections
            connectOnClick={false}   // Disable click connection
          >
            <Background />
            <MiniMap 
              pannable={true}
              style={{
                backgroundColor: 'var(--b1, #ffffff)',
                border: '1px solid var(--b2, #f2f2f2)',
                borderRadius: '0.5rem'
              }}
              nodeColor="var(--b2, #f2f2f2)"
              maskColor="rgba(0, 0, 0, 0.05)"
              onClick={handleMinimapClick}
              nodeClassName={(node) => node.className || ''}
              nodeStrokeWidth={3}
            />
            <Controls showInteractive={false} />
          </ReactFlow>
        </div>
      </div>
      <DetailModal open={isModalOpen} onClose={handleCancel} detail={modalContent} />
      {/* Chat Panel */}
      <ChatPanel 
        enableUpload={false}
        isOpen={isPanelOpen}
        onToggle={setIsPanelOpen}
        suggestions={followupQuestions}
        initialConversations={threadData?.initialData?.results || []}
        assetType="clinical-guideline"
        assetId={referenceId}
        threadId={activeThreadId}
        onThreadCreated={handleThreadCreated}
        selectedItems={selectList}
        onItemRemove={toggleAssetSelect}
        itemType="clinical-guideline"
        reference={selectedReference}
        treeName={treeName}
        source={source}
      />
    </>
  );
}
 
export function Flow({ threadData, treeData, handleBackButtonClick }) {
  const originalEdges = treeData?.sub_trees?.[0]?.edges;
  const originalNodes = treeData?.sub_trees?.[0]?.nodes;
  const pdfUrl = treeData?.doc_url;
  const treeName = treeData?.sub_trees?.[0]?.name;
  const referenceId = treeData?.id;
  const source = treeData?.source;
  const followupQuestions = treeData?.followup_questions

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ReactFlowProvider>
      <LayoutFlow
        threadData={threadData}
        originalEdges={originalEdges}
        originalNodes={originalNodes}
        pdfUrl={pdfUrl}
        treeName={treeName}
        referenceId={referenceId}
        source={source}
        followupQuestions={followupQuestions}
        handleBackButtonClick={handleBackButtonClick}
      />
    </ReactFlowProvider>
  )
}