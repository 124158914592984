import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const formatDate = (dateString) => {
  try {
    return format(new Date(dateString), 'MMM d, yyyy');
  } catch {
    return dateString;
  }
};

const renderCell = (params) => {
  const { value } = params;
  if (!value) return '';

  return (
    <div className="flex items-center">
      <span className="text-sm text-base-content/70">{value}</span>
    </div>
  );
};

const renderTitleCell = (params) => {
  const { value, data } = params;
  if (!value) return '';

  return (
    <div className="flex flex-col gap-1">
      <Link
        to={`/detail/catalyst/${data.id}`}
        target="_blank"
        className="text-sm text-base-content hover:underline"
      >
        {value}
      </Link>
      {data.lead_company && (
        <span className="text-xs text-base-content/60">{data.lead_company}</span>
      )}
    </div>
  );
};

const renderArrayCell = (params) => {
  const { value } = params;
  if (!value) return '';

  return (
    <ul className="flex flex-col gap-1 text-xs text-base-content/60">
      {value.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};

const renderSourceCell = (params) => {
  const { value } = params;
  // value is like "source": [
  //       {
  //         "href": "https://www.businesswire.com/news/home/20250220446612/en/Verastem-Oncology-Announces-Multiple-Data-Presentations-at-Society-of-Gynecologic-Oncology-2025-Annual-Meeting-on-Women%E2%80%99s-Cancer",
  //         "text": "Business Wire 02/20/2025"
  //     },
  //     {
  //         "href": "https://www.businesswire.com/news/home/20250123822428/en/Verastem-Oncology-Outlines-2025-Strategic-Priorities-and-Milestones-for-Novel-Pipeline-Targeting-RASMAPK-Pathway-Driven-Cancers",
  //         "text": "Business Wire 01/23/2025"
  //     }
  // ]
  if (!value) return '';

  return (
    <div className="items-center">
      {value.map((item) => (
        <a key={item.href} href={item.href} target="_blank" rel="noopener noreferrer" className="block hover:underline text-xs rounded-md text-base-content/70">
          {item.text}
        </a>
      ))}
    </div>
  );
};

const renderPhaseCell = (params) => {
  const { value } = params;
  if (!value) return '';

  return (
    <div className="flex items-center">
      <span className="px-2 py-0.5 text-xs rounded-md bg-success/10 text-success">
        {value}
      </span>
    </div>
  );
};

const renderDateCell = (params) => {
  const { value } = params;
  if (!value) return '';

  return (
    <div className="flex items-center">
      <span className="px-2 py-0.5 text-xs rounded-md text-base-content/70">
        {value}
      </span>
    </div>
  );
};

const renderImpactCell = (params) => {
  const { value } = params;
  if (!value) return '';

  return (
    <div className="flex items-center">
      <span className="px-2 py-0.5 text-xs rounded-md text-base-content/70">
        {value}
      </span>
    </div>
  );
};

const renderPartnersCell = (params) => {
  const { value } = params;
  if (!value || !Array.isArray(value) || value.length === 0) return '';

  return (
    <div className="flex flex-col gap-1">
      {value.map((partner, index) => (
        <span key={index} className="text-sm text-base-content/70">
          {partner}
        </span>
      ))}
    </div>
  );
};

export const CATALYST_COLUMN = [
  {
    field: 'rowIndex',
    headerName: '#',
    width: 70,
    valueGetter: (params) => params.node.rowIndex + 1,
  },
  {
    field: 'expected_date_start',
    headerName: 'Expected Date',
    minWidth: 130,
    cellRenderer: renderDateCell,
    valueGetter: (params) => {
      return params.data.expected_date_end === params.data.expected_date_start ? formatDate(params.data.expected_date_start) : `${formatDate(params.data.expected_date_start)} - ${formatDate(params.data.expected_date_end)}`;
    },
    sortable: true,
  },
  // {
  //   field: 'title',
  //   headerName: 'Title',
  //   minWidth: 300,
  //   flex: 2,
  //   cellRenderer: renderTitleCell,
  // },
  {
    field: 'drug_name',
    headerName: 'Drug',
    minWidth: 120,
    flex: 1,
    cellRenderer: renderCell,
  },
  {
    field: 'company',
    headerName: 'Company',
    minWidth: 120,
    cellRenderer: renderArrayCell,
  },
  {
    field: 'indication_name',
    headerName: 'Indication',
    minWidth: 150,
    flex: 1,
    cellRenderer: renderCell,
  },
  {
    field: 'clinical_analysis_phase',
    headerName: 'Phase',
    minWidth: 100,
    cellRenderer: renderPhaseCell,
  },
  {
    field: 'catalyst_type',
    headerName: 'Type',
    minWidth: 120,
    cellRenderer: renderImpactCell,
  },
  {
    field: 'sources',
    headerName: 'Source',
    minWidth: 120,
    cellRenderer: renderSourceCell,
  },
  // {
  //   field: 'partner_companies',
  //   headerName: 'Partners',
  //   minWidth: 200,
  //   flex: 1,
  //   cellRenderer: renderPartnersCell,
  // },
];
