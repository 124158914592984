import React from 'react';

import { useLanguage } from '../../utils/context/lang';
import { i18n } from '../i18n/guidance';

export default function EmptyState () {
  const { lang } = useLanguage();
  return (
    <div className="text-center py-12">
      <h3 className="text-lg font-medium text-base-content/70">
        {i18n.EMPTY_STATE.TITLE[lang]}
      </h3>
      <p className="text-sm text-base-content/50 mt-2">
        {i18n.EMPTY_STATE.DESCRIPTION[lang]}
      </p>
    </div>
  );
}
