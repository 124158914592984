/* eslint-disable no-useless-escape */
import { useEffect, useRef } from 'react';

import { extractThinkTag, processMarkdownToCleanHTML } from '../../utils/markdown-to-html';

const DisplayFormattedText = ({ text, onReferenceClick, formattedClass = '' }) => {
  const contentRef = useRef(null);
  const thinkRef = useRef(null);

  useEffect(() => {
    if (!contentRef.current || !onReferenceClick) return;

    // Add click handlers for reference links
    const links = Array.from(contentRef.current.querySelectorAll('a') || []).concat(Array.from(thinkRef?.current?.querySelectorAll('a') || []));
    links.forEach(link => {
      link.addEventListener('click', (e) => {
        // Check if this is a reference link (matches n pattern)
        const referenceMatch = link.textContent.match(/^(\d+)$/);
        const pdfMatch = link.href.match(/\.pdf$/i);
        if (referenceMatch) {
          e.preventDefault();
          const referenceId = referenceMatch[1];
          console.log('referenceId', referenceId);
          onReferenceClick(referenceId-1); // referenceId-1 is referenceIndex
        }
        if (pdfMatch) {
          e.preventDefault();
          onReferenceClick(link.href);
        }
      });

      // Add hover effect for reference links
      if (link.textContent.match(/^\[\d+\]$/) || link.href.match(/\.pdf$/i)) {
        link.classList.add('reference-link');
        link.title = 'Click to view reference';
      }
    });

    // Add copy button to code blocks
    const codeBlocks = contentRef.current.querySelectorAll('pre code');
    codeBlocks.forEach(block => {
      const copyButton = document.createElement('button');
      copyButton.innerHTML = 'Copy';
      copyButton.className = 'absolute right-2 top-2 px-2 py-1 rounded bg-base-100 text-sm opacity-50 hover:opacity-100';
      copyButton.onclick = () => {
        navigator.clipboard.writeText(block.textContent);
        copyButton.innerHTML = 'Copied!';
        setTimeout(() => copyButton.innerHTML = 'Copy', 2000);
      };
      block.parentElement.style.position = 'relative';
      block.parentElement.appendChild(copyButton);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', onReferenceClick);
      });
    };
  }, [ text, onReferenceClick ]);

  const { think, mainResponse } = extractThinkTag(text || '');
  const mainCleanHTML = processMarkdownToCleanHTML(mainResponse);
  const thinkCleanHTML = processMarkdownToCleanHTML(think);

  const FormattedText = (ref, cleanHTML, formattedClass) => {
    return <div
      ref={ref}
      className={`prose prose-base max-w-none
        prose-h1:text-xl prose-h1:mt-4 prose-h1:mb-0 prose-h1:font-bold prose-h1:no-underline
        prose-h2:text-lg prose-h2:mt-2 prose-h2:mb-0 prose-h2:mt-4 prose-h2:no-underline
        prose-h3:text-base prose-h3:mt-2 prose-h3:mb-0 prose-h3:mt-3 prose-h3:no-underline
        prose-h4:text-base prose-h4:mt-2 prose-h4:mb-0 prose-h4:mt-0 prose-h4:no-underline
        prose-p:text-primary-content prose-p:my-0 prose-p:no-underline 
        prose-li:text-primary-content prose-li:mt-2 prose-li:leading-relaxed 
        prose-ul:pl-5 prose-ul:my-2 prose-ol:pl-5
        prose-li:marker:text-primary-content
        prose-pre:bg-base-300 prose-pre:p-4 prose-pre:rounded-lg
        prose-code:text-secondary-content prose-code:bg-base-300 prose-code:px-1 prose-code:rounded
        hover:prose-a:text-primary-content hover:prose-a:underline prose-a:transition-colors
        [&_.reference-link]:text-primary 
        hover:[&_.reference-link]:text-primary-focus 
        [&_.reference-link]:cursor-pointer 
        [&_.reference-link]:border-b 
        [&_.reference-link]:border-dotted
        prose-table:border-collapse prose-table:w-full
        prose-td:p-2 prose-th:p-2
        prose-thead:bg-base-300 ${formattedClass}`}
      dangerouslySetInnerHTML={{ __html: cleanHTML }}
    />
  }

  return (
    <>
      {
        (think && thinkCleanHTML) &&  FormattedText(thinkRef, thinkCleanHTML, 'bg-primary/10 px-2 border-l-2 border-primary block opacity-60 text-sm my-2')
      }
      {FormattedText(contentRef, mainCleanHTML, '')}
    </>
  );
};

export default DisplayFormattedText;
