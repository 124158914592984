import React, { useEffect, useRef, useState } from 'react';

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline';
import {
  Handle,
  Position,
} from '@xyflow/react';
import { Tooltip } from 'antd';

import { selectionStyles } from '../../Common/styles/selection';
import { useLanguage } from '../../utils/context/lang';
import { i18n } from '../i18n/guidance';


function CustomNode({ data, id }) {
  const {
    isFoucused,
    attribute_text,
    onHeightChange,
    content,
    selectList,
    onItemClick,
    openPdf,
    page_num,
    onExclamationClick,
    isCollapsedTree, // 新增：控制子树折叠状态
    onToggleCollapse, // 新增：处理折叠/展开事件
    hasChildren // 新增：判断是否有子节点
  } = data || {};
  const nodeRef = useRef(null);
  const contentRef = useRef(null);
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const [ shouldShowCollapse, setShouldShowCollapse ] = useState(false);
  const [ fullHeight, setHullHeight ] = useState(0);
  const [ partHeight, setPartHeight ] = useState(0);
  const { lang } = useLanguage();
  
  useEffect(() => {
    if (nodeRef.current && contentRef.current) {
      const nodeHeight = nodeRef.current.offsetHeight;
      const contentHeight = contentRef.current.offsetHeight;
      // Check if content needs collapse button (more than 3 lines)
      const needCollapse = contentHeight > 72;
      const offsetHeight = attribute_text ? 65 : 25;
      const expandedHeight = contentHeight + offsetHeight + (needCollapse ? 32 : 0);
      const collapsedHeight = 72 + 32 + offsetHeight;

      setShouldShowCollapse(needCollapse); // Assuming 20px per line
      setIsCollapsed(needCollapse);
      setHullHeight(expandedHeight);
      setPartHeight(collapsedHeight);
      
      // If height needs to be passed to parent component
      if (onHeightChange) {
        onHeightChange({
          nodeHeight,
          contentHeight,
          id: id,
          height: needCollapse ? collapsedHeight : expandedHeight
        });
      }
    }
  }, [ content ]);

  const isSelected = selectList?.some(p => p.id === id);
  return (
    <div
      ref={nodeRef}
      className={`cursor-pointer w-full h-full px-4 py-2 shadow-md rounded-md bg-white text-black border-2 ${
        isFoucused ? 'border-sky-400' : isSelected ? 'border-success' : 'border-stone-400'
      }`}
      // onClick={() => onItemClick && onItemClick(data)}
    >
      {/* Add collapse tree button with tooltip */}
      {hasChildren && (
        <div className="absolute top-1 left-1 w-6 h-6 z-10">
          <Tooltip 
            title={isCollapsedTree ? i18n.EXPAND_NODE[lang] : i18n.COLLAPSE_NODE[lang]}
            placement="right"
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggleCollapse && onToggleCollapse(id);
              }}
              className="p-1 hover:bg-base-200 rounded-full transition-colors duration-200"
            >
              {isCollapsedTree ? (
                <PlusCircleIcon className="w-4 h-4" />
              ) : (
                <MinusCircleIcon className="w-4 h-4" />
              )}
            </button>
          </Tooltip>
        </div>
      )}
      {/* Selection Indicator */}
      {/* <div className='absolute top-2 right-2 w-4 h-4 z-10'>
        <div className={`${selectionStyles.checkbox} 
          ${isSelected ? selectionStyles.selected : selectionStyles.unselected}`}
        >
          {isSelected && (
            <svg className={selectionStyles.checkIcon} viewBox="0 0 24 24">
              <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
            </svg>
          )}
        </div>
      </div> */}
      <div className="flex flex-col w-full h-full justify-between">
        {/* content */}
        <div
          ref={contentRef}
          className={`text-base text-center whitespace-pre-wrap ${(openPdf && page_num) ? 'hover:underline' : ''} 
            ${isCollapsed ? 'max-h-[72px] line-clamp-3' : ''} overflow-hidden transition-all duration-200`}
          onClick={(e) => {
            if (openPdf && page_num) {
              e.stopPropagation();
              return openPdf(page_num);
            }
          }}
        >
          {content}
        </div>


        {shouldShowCollapse && (
          <div className="w-full flex justify-center my-1">
            <button
              className="btn btn-primary btn-xs gap-2 text-sm px-2 text-secondary-content"
              onClick={(e) => {
                e.stopPropagation();
                setIsCollapsed(!isCollapsed);
                if (onHeightChange) {
                  onHeightChange({
                    nodeHeight: 0,
                    contentHeight: 0,
                    id: id,
                    height: isCollapsed ? fullHeight : partHeight
                  });
                }
              }}
            >
              {isCollapsed ? (
                <>
                  {i18n.EXPAND[lang]} <ChevronDownIcon className="w-4 h-4" />
                </>
              ) : (
                <>
                  {i18n.COLLAPSE[lang]} <ChevronUpIcon className="w-4 h-4" />
                </>
              )}
            </button>
          </div>
        )}

        {
          attribute_text && (
            <div
              className="w-full px-4 py-2 rounded-md bg-success/10 border-1 flex justify-center items-center"
              onClick={(e) => {
                e.stopPropagation();
                return onExclamationClick && onExclamationClick(attribute_text);
              }}
            >
              <ExclamationCircleIcon className="h-6 w-6 text-success" />
            </div>
          )
        }
      </div>
 
      <Handle
        type="target"
        position={Position.Top}
      />
      <Handle
        type="source"
        position={Position.Bottom}
      />
    </div>
  );
}

export default CustomNode;