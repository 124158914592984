import React, { createContext, useContext, useEffect, useState } from 'react';

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { hide } from '@popperjs/core';
import { Button, Checkbox, Form, Input, message, Modal, Tabs } from 'antd';
import Cookies from 'js-cookie';

import { getMyInfo, signIn, signUp } from '../../api';

import { useLanguage } from './lang';

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const i18n = {
  SIGN_IN_TITLE: {
    en: 'Sign in to your account',
    zh: '登录您的帐户'
  },
  SIGN_UP_SUCCESS_TITLE: {
    en: "We've sent you a verification link",
    zh: '我们已向您发送验证链接'
  },
  SIGN_UP_SUCCESS_DESC: {
    en: 'You should receive an email any minute with a link to activate your account.',
    zh: '您将很快收到一封包含账户激活链接的电子邮件。'
  },
  SIGN_IN_TAB: {
    en: 'Sign In',
    zh: '登录'
  },
  SIGN_UP_TAB: {
    en: 'Sign Up',
    zh: '注册'
  },
  EMAIL_LABEL: {
    en: 'Email',
    zh: '邮箱'
  },
  EMAIL_PLACEHOLDER: {
    en: 'you@yourcompany.com',
    zh: '请输入邮箱'
  },
  PASSWORD_PLACEHOLDER: {
    en: 'Password (at least 6 characters)',
    zh: '请输入密码（至少6位）'
  },
  FORGOT_PASSWORD: {
    en: 'Forgot Password?',
    zh: '忘记密码？'
  },
  SIGN_IN_BUTTON: {
    en: 'Sign In',
    zh: '登录'
  },
  SIGN_UP_BUTTON: {
    en: 'Sign Up',
    zh: '注册'
  },
  EMAIL_REQUIRED: {
    en: 'Please input your email',
    zh: '请输入邮箱'
  },
  EMAIL_INVALID: {
    en: 'Please input a valid email address',
    zh: '请输入有效的邮箱地址'
  },
  PASSWORD_REQUIRED: {
    en: 'Please input your password',
    zh: '请输入密码'
  },
  PASSWORD_MIN: {
    en: 'Password must be at least 6 characters',
    zh: '密码至少6位'
  },
  AGREE_TERMS: {
    en: 'I agree to receive emails from Noah AI',
    zh: '我同意接收 Noah AI 的邮件'
  },
  AGREE_TERMS_ERROR: {
    en: 'Please agree to the terms',
    zh: '请同意并勾选服务条款'
  },
  SIGN_IN_ERROR: {
    en: 'Invalid username or password',
    zh: '用户名或密码错误'
  },
  SIGN_UP_ERROR: {
    en: 'Registration failed, please try again later or contact us',
    zh: '注册失败，请稍后再试或联系我们'
  }
};

const LoginModal = () => {
  const { login, hideModal, signup ,loginError, activeTab, setActiveTab } = useUser();
  const [ signupSuccess, setSignupSuccess ] = useState(false);
  const [ signinLoading, setSigninLoading ] = useState(false);
  const [ signupLoading, setSignupLoading ] = useState(false);
  const { lang } = useLanguage();

  const handleSigninSubmit = async (values) => {
    const { username, password } = values;
    setSigninLoading(true);
    try {
      await login(username, password);
    } catch (error) {
      message.error(i18n.SIGN_IN_ERROR[lang]);
    } finally {
      setSigninLoading(false);
    }
  };

  const handleSignupSubmit = async (values) => {
    const { username, password, canReceiveEmail } = values;
    setSignupLoading(true);
    try {
      const res = await signup(username, password, canReceiveEmail);
      if (res) {
        setSignupSuccess(true);
      } else {
        throw new Error('');
      }
    } catch (error) {
      message.error(error?.response?.data[Object.keys(error.response.data)[0]] ||
        i18n.SIGN_UP_ERROR[lang]);
    } finally {
      setSignupLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showSigninTab = () => {
    setSignupSuccess(false);
    setActiveTab('signin');
  };

  return (
    <>
      <Modal
        open={true}
        onCancel={hideModal}
        footer={null}
        width={400}
        style={{ 
          top: '30%',
        }}
      >
        {signupSuccess ? <div className="max-w-md mx-auto">
          <div className="-mt-2 text-center text-sm">
            <CheckCircleIcon className="text-green-500 mx-auto w-20 h-20" />
            <h1 className="text-xl mb-2 font-semibold">
              {i18n.SIGN_UP_SUCCESS_TITLE[lang]}
            </h1>
            <p className="text-lg mb-6 text-gray-400">
              {i18n.SIGN_UP_SUCCESS_DESC[lang]}
            </p>
            <abbr
              className="font-semibold text-lg leading-6 text-blue-600 hover:text-blue-500 cursor-pointer"
              onClick={showSigninTab}
            >
              {i18n.SIGN_IN_TITLE[lang]} <span aria-hidden="true">→</span>
            </abbr>
          </div>
        </div> :
          <Tabs
            defaultActiveKey={activeTab || 'signin'}
            centered
            items={[
              {
                key: 'signin',
                label: i18n.SIGN_IN_TAB[lang],
                children: (
                  <Form
                    name="signin"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={handleSigninSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="px-4 pt-2"
                  >
                    <Form.Item
                      label={i18n.EMAIL_LABEL[lang]}
                      name="username"
                      validateTrigger={[ 'onBlur' ]}
                      rules={[
                        { required: true, message: i18n.EMAIL_REQUIRED[lang] },
                        { type: 'email', message: i18n.EMAIL_INVALID[lang] }
                      ]}
                    >
                      <Input placeholder={i18n.EMAIL_PLACEHOLDER[lang]} />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      validateTrigger={[ 'onBlur' ]}
                      rules={[
                        { required: true, message: i18n.PASSWORD_REQUIRED[lang] },
                        { min: 6, message: i18n.PASSWORD_MIN[lang] }
                      ]}
                    >
                      <Input.Password placeholder={i18n.PASSWORD_PLACEHOLDER[lang]} />
                    </Form.Item>

                    <a
                      className="leading-6 flex justify-end cursor-pointer mb-2"
                      href="/forget-password"
                      target="_blank" 
                      rel="noopener noreferrer" 
                    >
                      <span className="text-gray-400 hover:text-gray-900">{i18n.FORGOT_PASSWORD[lang]}</span>
                    </a>

                    <Form.Item>
                      <Button 
                        className="!w-full !h-10 !border-none !relative !rounded !bg-blue-600 hover:!bg-blue-700 !text-white !flex !items-center !justify-center !overflow-hidden" 
                        htmlType="submit"
                        loading={signinLoading}
                      >
                        {i18n.SIGN_IN_BUTTON[lang]}
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
              {
                key: 'signup',
                label: i18n.SIGN_UP_TAB[lang],
                children: (
                  <Form
                    name="signup"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={handleSignupSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="px-4 pt-2"
                  >
                    <Form.Item
                      label={i18n.EMAIL_LABEL[lang]}
                      name="username"
                      validateTrigger={[ 'onBlur' ]}
                      rules={[
                        { required: true, message: i18n.EMAIL_REQUIRED[lang] },
                        { type: 'email', message: i18n.EMAIL_INVALID[lang] }
                      ]}
                    >
                      <Input placeholder={i18n.EMAIL_PLACEHOLDER[lang]} />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      validateTrigger={[ 'onBlur' ]}
                      rules={[
                        { required: true, message: i18n.PASSWORD_REQUIRED[lang] },
                        { min: 6, message: i18n.PASSWORD_MIN[lang] }
                      ]}
                    >
                      <Input.Password placeholder={i18n.PASSWORD_PLACEHOLDER[lang]} />
                    </Form.Item>

                    <Form.Item
                      name="canReceiveEmail"
                      valuePropName="checked"
                      validateTrigger={[ 'onBlur', 'onChange' ]}
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error(i18n.AGREE_TERMS_ERROR[lang])),
                        },
                      ]}
                    >
                      <Checkbox>{i18n.AGREE_TERMS[lang]}</Checkbox>
                    </Form.Item>

                    <Form.Item>
                      <Button 
                        className="!w-full !h-10 !border-none !relative !rounded !bg-blue-600 hover:!bg-blue-700 !text-white !flex !items-center !justify-center !overflow-hidden" 
                        htmlType="submit"
                        loading={signupLoading}
                      >
                        {i18n.SIGN_UP_BUTTON[lang]}
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
            ]}
          />
        }
      </Modal>
    </>
  )
};

export const UserProvider = ({ children }) => {
  const [ user, setUser ] = useState({});
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ loginError, setLoginError ] = useState(null);
  const [ activeTab, setActiveTab ] = useState('signin');
  
  useEffect(async () => {
    // Initialize user state from cookies on load
    const noahUser = Cookies.get('noahUser');
    const noahAccessToken = Cookies.get('noahAccessToken');

    if (noahUser && noahAccessToken) {
      try {
        const result = await getMyInfo(noahUser);
        console.log('look look look', {
          username: noahUser,
          info: result?.data,
        });
        setUser(result?.data);
      } catch (error) {
        logout();
      }
    } else {
      setUser(null)
    }
  }, []);

  const logout = () => {
    // Remove cookies
    Cookies.remove('noahUser');
    Cookies.remove('noahAccessToken');

    // Update state
    setUser(null);
    location.replace('/');
  };

  const login = async (username, password) => {
    try {
      const result = await signIn(username, password);      
      Cookies.set('noahUser', username);      
      Cookies.set('noahAccessToken', result.token);
      hideModal();
      // Update state
      const userResult = await getMyInfo(username);
      setUser(userResult?.data);
      // reload
      window.location.reload();
      return result;
    } catch (error) {
      console.log('login error -- ', error);
      setLoginError('Invalid username or password');
      throw error;
    }
  };

  const signup = async (username, password, canReceiveEmail) => {
    try {
      const result = await signUp(username, password, canReceiveEmail);      
      return result;
    } catch (error) {
      console.log('signup error -- ', error);
      throw error;
    }
  };

  const showModal = (tab = 'signin') => {
    setActiveTab(tab);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setLoginError(null);
    setActiveTab('signin');
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      logout, 
      login, 
      signup, 
      showModal, 
      hideModal,
      activeTab,
      setActiveTab
    }}>
      {children}
      {isModalVisible && <LoginModal />}
    </UserContext.Provider>
  );
};
