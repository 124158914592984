import { format } from 'date-fns';
import { motion } from 'framer-motion';

import { selectionStyles } from '../../styles/selection';

const Badge = ({ children, variant = 'default' }) => {
  const variants = {
    phase: 'bg-emerald-100 text-emerald-800 border border-emerald-200',
    drug: 'bg-indigo-600 text-white',
    disease: 'bg-sky-100 text-sky-800 border border-sky-200',
    impact: 'bg-amber-100 text-amber-800 border border-amber-200',
    date: 'bg-purple-100 text-purple-800 border border-purple-200',
    default: 'bg-gray-100 text-gray-700 border border-gray-200'
  };

  return (
    <span className={`px-2 py-0.5 text-xs font-medium rounded-md ${variants[variant]}`}>
      {children}
    </span>
  );
};

const CatalystCard = ({ item, isSelected, onClick }) => {
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMM d, yyyy');
    } catch {
      return dateString;
    }
  };

  return (
    <motion.div
      onClick={onClick}
      whileTap={{ scale: 0.98 }}
      className={`bg-white rounded-lg overflow-hidden relative cursor-pointer transition-colors duration-200 shadow-sm
        ${isSelected 
      ? 'ring-2 ring-indigo-500 border border-indigo-500' 
      : 'border border-gray-200 hover:border-gray-300 hover:shadow'}`}
      aria-selected={isSelected}
      role="option"
    >
      {/* Selection Indicator - Improved for accessibility */}
      <div className="absolute top-3 right-3 w-6 h-6 z-10">
        <div className={`w-full h-full rounded-full transition-all duration-300
          ${isSelected 
      ? 'bg-indigo-600 border-2 border-indigo-600 shadow-md' 
      : 'border-2 border-gray-400 bg-white hover:border-indigo-500'}`}
        >
          {isSelected && (
            <svg className="w-full h-full text-white p-0.5" viewBox="0 0 24 24" aria-hidden="true">
              <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
            </svg>
          )}
        </div>
      </div>

      <div className="p-4">
        {/* Header */}
        <div className="mb-3 pr-6">
          <div className="space-y-1">
            <a 
              href={`/detail/catalyst/${item.id}`}
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded"
              onClick={(e) => e.stopPropagation()}
              aria-label={`View details for ${item.title}`}
            >
              <h3 className="text-base font-medium text-gray-900">
                {item.title}
              </h3>
            </a>
            <div className="text-sm text-gray-600">
              {item.lead_company}
            </div>
          </div>
        </div>

        {/* Primary Info Badges */}
        <div className="flex flex-wrap gap-2 mb-4">
          {item.drug_name && (
            <Badge variant="drug">{item.drug_name}</Badge>
          )}
          {item.indication_name && (
            <Badge variant="disease">{item.indication_name}</Badge>
          )}
          {item.clinical_analysis_phase && (
            <Badge variant="phase">Phase {item.clinical_analysis_phase}</Badge>
          )}
          {item.catalyst_impact && (
            <Badge variant="impact">{item.catalyst_impact}</Badge>
          )}
        </div>

        {/* Additional Details */}
        <div className="pt-3 border-t border-gray-200">
          <div className="space-y-2">
            {/* Expected Dates */}
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-600 font-medium">Expected Date:</span>
              <Badge variant="date">
                {formatDate(item.expected_date_start)}
              </Badge>
            </div>

            {/* Partner Companies */}
            {item.partner_companies && item.partner_companies.length > 0 && (
              <div className="text-sm">
                <span className="text-gray-600 font-medium">Partners: </span>
                <span className="text-gray-800">
                  {item.partner_companies.join(', ')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CatalystCard;
