import React from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { getClinicalGuidances } from '../api';
import { useLanguage } from '../utils/context/lang';

import EmptyState from './components/EmptyState';
import { i18n } from './i18n/guidance';

const GuidanceCard = ({ guidance, onClick }) => {
  const { lang } = useLanguage();
  
  return (
    <div
      onClick={onClick}
      className="group h-full p-6 border border-gray-200 rounded-lg overflow-hidden 
                 hover:shadow-xl hover:border-gray-300 transition-all duration-300 ease-in-out
                 bg-gradient-to-br from-white to-gray-50 
                 cursor-pointer flex flex-col justify-between"
    >
      <h3 className="text-xl font-semibold text-gray-800 group-hover:text-blue-600 
                   transition-colors duration-300 line-clamp-2">
        {guidance.name}
      </h3>
      
      <div className="space-y-4 mt-2">
        <div className="flex flex-wrap gap-2">
          {guidance.source && (
            <span className="px-3 py-1 text-xs font-medium rounded-full 
                         bg-amber-50 text-amber-700 border border-amber-200">
              {i18n.SOURCE[lang]}: {guidance.source}
            </span>
          )}
          {guidance.publication_date && (
            <span className="px-3 py-1 text-xs font-medium rounded-full 
                         bg-lime-50 text-lime-700 border border-lime-200">
              {i18n.PUBLICATION_DATE[lang]}: {guidance.publication_date}
            </span>
          )}
          {guidance.version && (
            <span className="px-3 py-1 text-xs font-medium rounded-full 
                         bg-sky-50 text-sky-700 border border-sky-200">
              {i18n.VERSION[lang]}: {guidance.version}
            </span>
          )}
        </div>
        <div className="flex items-center gap-2 text-sm font-medium text-blue-600
                      group-hover:text-blue-700 transition-colors">
          <span>{i18n.MORE_INFO[lang]}</span>
          <ArrowRightIcon className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
        </div>
      </div>
    </div>
  );
};

export function InternalGuidelines() {
  const navigate = useNavigate();
  const { associationId } = useParams();
  const guidances = useLoaderData();
  const { lang } = useLanguage();

  return (
    <div className="min-h-screen bg-cream py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center relative justify-center gap-4 mb-8">
          {/* Back button */}
          <button
            onClick={() => navigate('/guidance/')}
            className="absolute top-[9px] left-0 group flex items-center gap-2 text-base-content/70 hover:text-base-content transition-colors duration-200"
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="text-sm font-medium">{i18n.BACK[lang]}</span>
          </button>
          <h1 className="text-3xl font-bold text-center">
            {associationId}
          </h1>
        </div>

        {guidances?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {guidances.map((guidance) => (
              <GuidanceCard
                key={guidance.name}
                guidance={guidance}
                onClick={() => navigate(`/guidance/${associationId}/${guidance.id}`)}
              />
            ))}
          </div>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}

export async function internalGuidelinesLoader(location) {
  const { associationId } = location.params;
  const guidances = await getClinicalGuidances(associationId);
  
  return guidances || null;
}

// import React, { Suspense, useState } from 'react';

// import { ArrowRightIcon } from '@heroicons/react/24/outline';
// import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

// import { getClinicalGuidances, getConferenceSettings } from '../api';
// import DynamicQueryForm from '../Common/Medical/Form/DynamicQueryForm';
// import { useAlert } from '../utils/context/alert';
// import { useLanguage } from '../utils/context/lang';

// import EmptyState from './components/EmptyState';
// import SelectedItems from './components/SelectedItems';
// import { i18n } from './i18n/guidance';

// const formatQueryValues = (queryValues) => {
//   const resultValues = JSON.parse(JSON.stringify(queryValues));
//   Object.keys(resultValues).forEach((key) => {
//     const item = resultValues[key];
//     const data = item?.data;
//     const formatArray = (array) => {
//       return array.map((subItem) => {
//         return subItem[subItem.length - 1]
//       })
//     }
//     if (Array.isArray(item) && Array.isArray(item[0])) {
//       resultValues[key] = formatArray(item);
//     } else if (Array.isArray(data) && Array.isArray(data[0])) {
//       resultValues[key].data = formatArray(data);
//     }
//   });
//   return resultValues;
// }

// const PaperCardSkeleton = () => (
//   <div className="bg-base-100 rounded-lg overflow-hidden border border-base-200 animate-pulse">
//     <div className="p-4 border-b border-base-200">
//       <div className="flex justify-between mb-4">
//         <div className="h-4 w-16 bg-base-200 rounded"></div>
//         <div className="h-4 w-24 bg-base-200 rounded"></div>
//       </div>
//       <div className="h-6 w-3/4 bg-base-200 rounded mb-3"></div>
//       <div className="h-4 w-1/2 bg-base-200 rounded"></div>
//     </div>
//     <div className="p-4 space-y-4">
//       <div className="space-y-2">
//         <div className="h-4 w-full bg-base-200 rounded"></div>
//         <div className="h-4 w-5/6 bg-base-200 rounded"></div>
//       </div>
//       <div className="flex gap-2">
//         <div className="h-6 w-20 bg-base-200 rounded"></div>
//         <div className="h-6 w-20 bg-base-200 rounded"></div>
//       </div>
//     </div>
//   </div>
// );

// const LoadingGrid = ({ count = 6 }) => (
//   <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
//     {[ ...Array(count) ].map((_, i) => (
//       <PaperCardSkeleton key={i} />
//     ))}
//   </div>
// );

// const target = [
//   {
//     value: 'test-1',
//     id: 1,
//   },
//   {
//     value: 'test-2',
//     id: 2,
//   },
//   {
//     value: 'test-3',
//     id: 3,
//   },
//   {
//     value: 'test-4',
//     id: 4,
//   }
// ]

// const GuidanceCard = ({ guidance, onClick }) => {
//   const { lang } = useLanguage();
  
//   return (
//     <div
//       onClick={onClick}
//       className="p-4 border-gray-100 rounded-lg overflow-hidden shadow-sm 
//                  hover:shadow-lg transition-all duration-300 border bg-gradient-to-br from-gray-50 to-slate-50 
//                  cursor-pointer flex flex-col justify-between text-gray-600"
//     >
//       <h3 className="mb-4 transition-colors text-lg font-semibold group-hover:text-gray-700 line-clamp-2">{guidance.name}</h3>
//       <div className="flex items-center gap-2 text-sm bg-slate-100 backdrop-blur-sm rounded-lg p-2 hover:bg-slate-200 transition-colors w-fit">
//         <p>{i18n.MORE_INFO[lang]}</p>
//         <ArrowRightIcon className="w-4 h-4" />
//       </div>
//     </div>
//   );
// };

// // TODO add search & filter
// export function InternalGuidelines() {
//   const navigate = useNavigate();
//   const { associationId } = useParams();
//   const { guidances: initialGuidances, guidancesSettings } = useLoaderData();
//   const [ filters, setFilters ] = useState({});
//   const [ guidances, setGuidances ] = useState(initialGuidances || {});
//   const [ isLoading, setIsLoading ] = useState(false);
//   const [ queryValues, setQueryValues ] = useState({});
//   const [ selectedLabels, setSelectedLabels ] = useState([]);
//   const { lang } = useLanguage();
//   const showAlert = useAlert();

//   const toggleAssetSelect = (asset) => {    
//     setSelectedLabels(prev => {
//       const isSelected = prev.some(p => p.id === asset.id);
//       if (isSelected) {
//         return prev.filter(p => p.id !== asset.id);
//       } else {
//         return [ ...prev, asset ];
//       }
//     });
//     // TODO: handle filter
//   };

//   // Handle query submission
//   const handleQuerySubmit = async (newQueryValues) => {
//     const formattedQueryValues = formatQueryValues(newQueryValues);
//     setIsLoading(true);
//     setQueryValues(formattedQueryValues);
//     try {
//       const newData = await getClinicalGuidances(associationId, formattedQueryValues, 1);
//       setGuidances(newData);
//     } catch (error) {
//       showAlert('error', 'Failed to query papers');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-cream pb-12">
//       <div className="">
//         {/* <h1 className="text-3xl font-bold mb-8">
//           {associationId}
//         </h1> */}
//         <div className="sticky top-0 z-30 bg-base-100/80 backdrop-blur-sm border-b border-base-200">
//           <div className="max-w-7xl mx-auto px-4 md:px-8 py-4">
//             <h1 className="flex flex-wrap items-center justify-between gap-4 mb-6 text-2xl font-semibold tracking-tight text-base-content">
//               {/* Title */}
//               {associationId}
//             </h1>
//             {/* selected labels */}
//             <SelectedItems
//               items={target}
//               selectedItems={selectedLabels}
//               onChange={toggleAssetSelect}
//             />
//             {/* Query Form */}
//             {guidancesSettings.schema?.fields && (
//               <Suspense 
//                 fallback={
//                   <div className="h-16 animate-pulse bg-base-200/50 rounded-xl backdrop-blur-sm" />
//                 }
//               >
//                 <div className="bg-base-100/50 backdrop-blur-sm border border-base-200 
//                     rounded-xl shadow-sm">
//                   <DynamicQueryForm
//                     fields={guidancesSettings.schema.fields}
//                     values={filters}
//                     totalCount={10}
//                     onChange={setFilters}
//                     onSearch={handleQuerySubmit}
//                     defaultExpanded={false}
//                   />
//                 </div>
//               </Suspense>
//             )}
//           </div>
//         </div>

//         {guidances?.length > 0 ? isLoading ? (
//           <LoadingGrid count={6} />
//         ) : (
//           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 transition-all duration-300 ease-in-out max-w-7xl mx-auto px-4 md:px-8 py-6">
//             {guidances.map((guidance) => (
//               <GuidanceCard
//                 key={guidance.name}
//                 guidance={guidance}
//                 onClick={() => navigate(`/guidance/${associationId}/${guidance.id}`)}
//               />
//             ))}
//           </div>
//         ) : (
//           <EmptyState />
//         )}
//       </div>
//     </div>
//   );
// }

// export async function internalGuidelinesLoader(location) {
//   const { associationId } = location.params;
//   // Get initial query values
//   // let initialQueryValues = {};
//   // const id = new URLSearchParams(new URL(location.request.url).search).get('id');

//   // const queryValuesForSearch = formatQueryValues(initialQueryValues);
//   // Fetch data using the format initial query values
//   const [ guidancesSettings, guidances ] = await Promise.all([
//     getConferenceSettings('ash'),
//     getClinicalGuidances(associationId)
//   ]);
  
//   return {
//     guidancesSettings,
//     guidances
//   };
// }
