import React, { useEffect, useRef } from 'react';

import { BeakerIcon, BuildingOfficeIcon, CalendarIcon, HeartIcon, LinkIcon, UsersIcon } from '@heroicons/react/24/outline';
import { Pill } from '@phosphor-icons/react';
import { useLoaderData } from 'react-router-dom';

import { getCatalystDetail } from '../api.js';
import { useLanguage } from '../utils/context/lang.js';
import { useLayout } from '../utils/context/layout.js';

// i18n for catalyst
const i18n = {
  TITLE: {
    en: 'Title',
    zh: '标题'
  },
  DRUG_NAME: {
    en: 'Drug Name',
    zh: '药物名称'
  },
  INDICATION: {
    en: 'Indication',
    zh: '适应症'
  },
  IMPACT: {
    en: 'Impact',
    zh: '影响'
  },
  PHASE: {
    en: 'Phase',
    zh: '临床分析阶段'
  },
  TIMELINE: {
    en: 'Timeline',
    zh: '时间线'
  },
  START_DATE: {
    en: 'Expected Start',
    zh: '预计开始日期'
  },
  END_DATE: {
    en: 'Expected End',
    zh: '预计结束日期'
  },
  RELATED_COMPANIES: {
    en: 'Related Companies',
    zh: '相关公司'
  },
  LEAD_COMPANY: {
    en: 'Lead Company',
    zh: '主要公司'
  },
  PARTNER_COMPANIES: {
    en: 'Partner Companies',
    zh: '合作公司'
  },
  CATALYST_TYPE: {
    en: 'Catalyst Type',
    zh: '催化剂类型'
  },
  CONTEXT: {
    en: 'Context',
    zh: '上下文'
  },
  UPDATES: {
    en: 'Updates',
    zh: '更新'
  },
  SOURCES: {
    en: 'Sources',
    zh: '来源'
  }
};

// Helper component for info cards
const InfoCard = ({ icon, label, value, className = '' }) => (
  <div className={`flex items-start space-x-3 ${className}`}>
    <div className="flex-shrink-0 mt-1">
      {icon}
    </div>
    <div>
      <p className="text-xs font-medium text-gray-500 uppercase tracking-wider">
        {label}
      </p>
      <p className="text-sm font-medium text-gray-900 mt-1">
        {value}
      </p>
    </div>
  </div>
);

// Phase badge component
const PhaseBadge = ({ phase }) => {
  let bgColor = 'bg-blue-50';
  let textColor = 'text-blue-700';
  
  if (phase === 'Approved') {
    bgColor = 'bg-green-50';
    textColor = 'text-green-700';
  } else if (phase === 'III') {
    bgColor = 'bg-purple-50';
    textColor = 'text-purple-700';
  } else if (phase === 'IV') {
    bgColor = 'bg-indigo-50';
    textColor = 'text-indigo-700';
  }
  
  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${bgColor} ${textColor}`}>
      {phase === 'Approved' ? 'Approved' : `Phase ${phase}`}
    </span>
  );
};

// Source links component
const SourceLinks = ({ links }) => {
  if (!links || links.length === 0) return null;
  
  return (
    <div className="mt-3">
      <p className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">Sources</p>
      <div className="space-y-1">
        {links.map((link, index) => (
          <div key={index} className="flex items-center">
            <LinkIcon className="h-3 w-3 text-gray-400 mr-1.5" />
            {link.href ? (
              <a 
                href={link.href} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-xs text-blue-600 hover:text-blue-800 hover:underline"
              >
                {link.text}
              </a>
            ) : (
              <span className="text-xs text-gray-600">{link.text}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export function CatalystDetail() {
  const { catalystDetailData } = useLoaderData();
  const containerRef = useRef(null);
  const { collapseSidebar } = useLayout();
  const { lang } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth < 400) {
          collapseSidebar();
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ collapseSidebar ]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(lang === 'en' ? 'en-US' : 'zh-CN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (!catalystDetailData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-gray-500">No data available</p>
      </div>
    );
  }

  // Determine if we have updates or context data
  const hasUpdates = catalystDetailData.updates && catalystDetailData.updates.length > 0;
  const hasContext = catalystDetailData.context && catalystDetailData.context.length > 0;

  return (
    <div ref={containerRef} className="bg-white min-h-screen">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="border-b border-gray-200 pb-5 mb-6">
          <h1 className="text-2xl font-bold text-gray-900 leading-tight">
            {catalystDetailData.title}
          </h1>
          <div className="mt-2 flex flex-wrap items-center gap-3">
            <PhaseBadge phase={catalystDetailData.clinical_analysis_phase} />
            <span className="text-sm font-medium text-gray-500">
              {catalystDetailData.catalyst_type}
            </span>
          </div>
        </div>

        {/* Main content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left column - Key details */}
          <div className="lg:col-span-2 space-y-6">
            {/* Key information card */}
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm">
              <div className="px-6 py-5 border-b border-gray-200 bg-gray-50">
                <h2 className="text-base font-medium text-gray-900">Key Information</h2>
              </div>
              <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoCard 
                  icon={<Pill className="h-5 w-5 text-red-500" />}
                  label={i18n.DRUG_NAME[lang]}
                  value={catalystDetailData.drug_name}
                />
                <InfoCard 
                  icon={<HeartIcon className="h-5 w-5 text-red-500" />}
                  label={i18n.INDICATION[lang]}
                  value={catalystDetailData.indication_name}
                />
                <InfoCard 
                  icon={<BeakerIcon className="h-5 w-5 text-purple-500" />}
                  label={i18n.IMPACT[lang]}
                  value={catalystDetailData.catalyst_impact || 'Not specified'}
                />
                <InfoCard 
                  icon={<CalendarIcon className="h-5 w-5 text-green-500" />}
                  label={i18n.TIMELINE[lang]}
                  value={`${formatDate(catalystDetailData.expected_date_start)} - ${formatDate(catalystDetailData.expected_date_end)}`}
                />
              </div>
            </div>

            {/* Updates information */}
            {hasUpdates && (
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm">
                <div className="px-6 py-5 border-b border-gray-200 bg-gray-50">
                  <h2 className="text-base font-medium text-gray-900">{i18n.UPDATES[lang]}</h2>
                </div>
                <div className="divide-y divide-gray-200">
                  {catalystDetailData.updates.map((update, index) => (
                    <div key={index} className="p-6">
                      {update.context?.title && (
                        <h3 className="text-sm font-medium text-gray-900 mb-2">{update.context.title}</h3>
                      )}
                      {update.context?.info && (
                        <p className="text-sm text-gray-600">{update.context.info}</p>
                      )}
                      {update.context?.label && (
                        <div className="mt-2">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-50 text-blue-700">
                            {update.context.label}
                          </span>
                        </div>
                      )}
                      {update.source?.links && <SourceLinks links={update.source.links} />}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Context information (for backward compatibility) */}
            {!hasUpdates && hasContext && (
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm">
                <div className="px-6 py-5 border-b border-gray-200 bg-gray-50">
                  <h2 className="text-base font-medium text-gray-900">{i18n.CONTEXT[lang]}</h2>
                </div>
                <div className="divide-y divide-gray-200">
                  {catalystDetailData.context.map((item, index) => (
                    <div key={index} className="p-6">
                      {item.title && (
                        <h3 className="text-sm font-medium text-gray-900 mb-2">{item.title}</h3>
                      )}
                      <p className="text-sm text-gray-600">{item.info}</p>
                      {item.label && (
                        <div className="mt-2">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-50 text-blue-700">
                            {item.label}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Right column - Companies */}
          <div className="space-y-6">
            {/* Companies card */}
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm">
              <div className="px-6 py-5 border-b border-gray-200 bg-gray-50">
                <h2 className="text-base font-medium text-gray-900">{i18n.RELATED_COMPANIES[lang]}</h2>
              </div>
              <div className="p-6 space-y-6">
                <InfoCard 
                  icon={<BuildingOfficeIcon className="h-5 w-5 text-indigo-500" />}
                  label={i18n.LEAD_COMPANY[lang]}
                  value={(
                    <div className="flex items-center mt-1">
                      <span className="text-sm font-medium text-gray-900">{catalystDetailData.lead_company}</span>
                      {catalystDetailData.lead_company_symbol && (
                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          {catalystDetailData.lead_company_symbol}
                        </span>
                      )}
                    </div>
                  )}
                />

                {catalystDetailData.partner_companies?.length > 0 && (
                  <InfoCard 
                    icon={<UsersIcon className="h-5 w-5 text-teal-500" />}
                    label={i18n.PARTNER_COMPANIES[lang]}
                    value={(
                      <div className="flex flex-wrap gap-2 mt-1">
                        {catalystDetailData.partner_companies.map((company, index) => (
                          <span 
                            key={index} 
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                          >
                            {company}
                          </span>
                        ))}
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function catalystDetailLoader({ params }) {
  const { id } = params;
  const result = await getCatalystDetail(id);
  return { 
    id,
    catalystDetailData: result?.[0] || null,
  };
}