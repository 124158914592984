export const i18n = {
  TITLE: {
    zh: '临床指南',
    en: 'Clinical Guidance',
    jp: '臨床ガイドライン'
  },
  INACTIVE_GUIDANCE: {
    zh: '该指南当前未激活',
    en: 'This guidance is currently inactive',
    jp: 'このガイドラインは現在無効です'
  },
  EMPTY_STATE: {
    TITLE: {
      zh: '暂无相关结果',
      en: 'No Related Results',
      jp: '関連する結果がありません'
    },
    DESCRIPTION: {
      zh: '当前分类下还没有任何内容',
      en: 'No content available in current category',
      jp: '現在のカテゴリにはコンテンツがありません'
    }
  },
  MORE_INFO: {
    zh: '更多信息',
    en: 'More info',
    jp: '詳細情報'
  },
  VIEW_MORE: {
    zh: '查看详情',
    en: 'View more',
    jp: '詳細を見る'
  },
  BACK: {
    zh: '返回',
    en: 'Back',
    jp: '戻る'
  },
  NO_SEARCH_RESULT: {
    zh: '没有搜索结果',
    en: 'No search result',
    jp: '検索結果なし'
  },
  SEARCH_PLACEHOLDER: {
    zh: '关键词搜索',
    en: 'Search keywords',
    jp: 'キーワード検索'
  },
  EXPAND: {
    zh: '展开',
    en: 'Expand',
    jp: '展開'
  },
  COLLAPSE: {
    zh: '收起',
    en: 'Collapse',
    jp: '折りたたむ'
  },
  EXPAND_NODE: {
    zh: '展开子节点',
    en: 'Expand Nodes',
    jp: 'ノードを展開'
  },
  COLLAPSE_NODE: {
    zh: '折叠子节点',
    en: 'Collapse Nodes',
    jp: 'ノードを折りたたむ'
  },
  PLEASE_LOGIN: {
    zh: '请登录',
    en: 'Please Login',
    jp: 'ログインしてください'
  },
  LOGIN_TO_VIEW: {
    zh: '登录后即可查看完整内容',
    en: 'Login to view full content',
    jp: 'ログインして完全なコンテンツを表示'
  },
  SOURCE: {
    en: 'Source',
    zh: '来源',
    jp: '出典'
  },
  PUBLICATION_DATE: {
    en: 'Publication Date',
    zh: '发布日期',
    jp: '公開日'
  },
  VERSION: {
    en: 'Version',
    zh: '版本',
    jp: 'バージョン'
  }
};