import { useEffect, useRef, useState } from 'react';

import { getReport } from '../../api.js';
import { useLanguage } from '../../utils/context/lang.js';

const ReportDownloadBtn = ({ status, attachmentKey, threadId }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ isPolling, setIsPolling ] = useState(false);
  const pollingIntervalRef = useRef(null);
  const { t } = useLanguage();

  // Effect to handle polling when status is 'processing'
  useEffect(() => {
    // Start polling if status is 'processing'
    if (status === 'processing' && !isPolling) {
    // TODO
    //   setIsPolling(true);
    //   pollReportStatus();
    }
    
    // Clean up polling interval on unmount
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [ status, attachmentKey, threadId ]);

  const pollReportStatus = () => {
    // Clear any existing interval
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }

    // Set up polling interval (every 5 seconds)
    pollingIntervalRef.current = setInterval(async () => {
      try {
        const response = await getReport(attachmentKey, threadId);
        
        // If report is complete (has a URL), stop polling and trigger download
        if (response && response.url) {
          clearInterval(pollingIntervalRef.current);
          setIsPolling(false);
          
          // Trigger download
          const link = document.createElement('a');
          link.href = response.url;
          link.setAttribute('download', '');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        // If report status is no longer 'processing', stop polling
        else if (response && response.status && response.status !== 'processing') {
          clearInterval(pollingIntervalRef.current);
          setIsPolling(false);
          
          if (response.status === 'error') {
            setError(new Error(response.message || 'Report generation failed'));
          }
        }
      } catch (error) {
        clearInterval(pollingIntervalRef.current);
        setIsPolling(false);
        setError(error);
      }
    }, 5000); // Poll every 5 seconds
  };

  const handleDownload = async () => {
    // If already polling or status is processing, don't do anything
    if (isPolling || status === 'processing') {
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await getReport(attachmentKey, threadId);
      
      // If report is in processing state, start polling
      if (response && response.status === 'processing') {
        setIsPolling(true);
        pollReportStatus();
        setIsLoading(false);
        return;
      }
      
      // If report is ready, download it
      const link = document.createElement('a');
      link.href = response.url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBtnClassName = () => {
    const baseClasses = 'inline-flex items-center px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2';
    
    if (isLoading || isPolling || status === 'processing') {
      return `${baseClasses} bg-gray-400 text-white cursor-not-allowed`;
    }
    if (error) {
      return `${baseClasses} bg-red-600 hover:bg-red-700 text-white focus:ring-red-500`;
    }
    return `${baseClasses} bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500`;
  };

  // Determine button state based on status, isPolling, isLoading, and error
  const getButtonState = () => {
    if (isPolling || status === 'processing') {
      return 'polling';
    }
    if (isLoading) {
      return 'loading';
    }
    if (error) {
      return 'error';
    }
    return 'ready';
  };

  const buttonState = getButtonState();

  return (
    <button
      onClick={handleDownload}
      disabled={isLoading || isPolling || status === 'processing'}
      className={getBtnClassName()}
    >
      {buttonState === 'polling' ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Generating Report...
        </>
      ) : buttonState === 'loading' ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Processing...
        </>
      ) : buttonState === 'error' ? (
        <>
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          Try Again
        </>
      ) : (
        <>
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
          Download Report
        </>
      )}
    </button>
  );
};

export default ReportDownloadBtn;
