import React, { useEffect, useMemo, useRef, useState } from 'react';

import { 
  ArrowLeftIcon,
  ClockIcon, 
  PlusIcon 
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router';
import { useLoaderData, useParams } from 'react-router-dom';

import { getDiscoverDetail, getThreadMeta, getThreadTasks } from '../api';
import DisplayFormattedText from '../AskPage/components/markdown.js';
import SingleLineInput from '../AskPage/components/singleLineInput.js';
import ChatPanel from '../Common/ChatPanal';
import { i18n } from '../Common/i18n/common.js';
import { useAlert } from '../utils/context/alert.js';
import { useHistory } from '../utils/context/history.js';
import { useLanguage } from '../utils/context/lang.js';
import { useLayout } from '../utils/context/layout';

import ReferencePill from './components/referencePill.js';

export function ArticleDetail() {
  const { articleId, threadId: urlThreadId } = useParams();
  const { article, threadData } = useLoaderData();
  const navigate = useNavigate();
  const { lang } = useLanguage();
  const paragraphsRef = useRef([]);
  const showAlert = useAlert();
  const { exploreContext, setExploreContext } = useHistory();
  const [ currentIndex, setCurrentIndex ] = useState(null);
  // Add mobile detection for initial panel state
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [ isPanelOpen, setIsPanelOpen ] = useState(!isMobile);
  const visibilityMap = useRef({});
  const [ selectedReference, setSelectedReference ] = useState(null);
  const [ activeThreadId, setActiveThreadId ] = useState(urlThreadId);
  const containerRef = useRef(null);
  const { collapseSidebar } = useLayout();
  const { updateHistory } = useHistory();
  // request article data
  // useEffect(() => {
  //   getDiscoverDetail(25).then((data) => {
  //     console.log(data);
  //   });
  // }, []);

  const scrollToParagraph = (index) => {
    // Remove immediate currentIndex update
    paragraphsRef.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const onSubmit = (text) => {
    const queryParams = new URLSearchParams({
      q: text,
      contextUUID: articleId,
      contextType: 'discoverArticle',
    }).toString();
    navigate(`/ask/?${queryParams}`, {
      state: { title: article.title, sourceURL: `/discover/article/${articleId}` },
    });
    console.log(text, articleId);
  };

  useEffect(() => {
    window.gtag('event', 'page_view', {
      event_category: 'page_view',
      event_label: 'article_detail',
      article_id: articleId,
    });
  }, [ articleId ]);

  const onReferenceClick = (reference) => {
    window.gtag('event', 'click', {
      event_category: 'reference',
      event_label: reference.title,
    });
    window.open(reference.link, '_blank');
  };

  const onFollowUpQuestionClick = (question) => {
    window.gtag('event', 'click', {
      event_category: 'follow_up_question',
      event_label: question,
    });
    onSubmit(question);
  };

  useEffect(() => {
    const handleVisibilityChange = (entries) => {
      entries.forEach((entry) => {
        const index = paragraphsRef.current.indexOf(entry.target);
        if (index !== -1) {
          visibilityMap.current[index] = entry.intersectionRatio;
        }
      });

      // Find the paragraph with the highest visibility
      let maxRatio = 0;
      let mostVisibleIndex = -1;

      Object.keys(visibilityMap.current).forEach((key) => {
        const ratio = visibilityMap.current[key];
        if (ratio > maxRatio) {
          maxRatio = ratio;
          mostVisibleIndex = key;
        }
      });

      if (mostVisibleIndex !== -1) {
        setCurrentIndex(parseInt(mostVisibleIndex, 10));
      }
    };

    const observerOptions = {
      threshold: Array.from({ length: 101 }, (_, i) => i / 100), // Thresholds from 0 to 1
    };

    const observer = new IntersectionObserver(
      handleVisibilityChange,
      observerOptions
    );

    paragraphsRef.current.forEach((paragraph) => {
      if (paragraph) {
        observer.observe(paragraph);
      }
    });

    // Cleanup function
    return () => {
      paragraphsRef.current.forEach((paragraph) => {
        if (paragraph) {
          observer.unobserve(paragraph);
        }
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleToggleBoth = () => {
    setIsPanelOpen(false);
    setSelectedReference(null);
  };

  const handleReferenceClick = (reference, index) => {
    const extractLinkDomain = (url) => {
      const urlObj = new URL(url);
      return urlObj.hostname;
    };
    setIsPanelOpen(true);
    setSelectedReference({
      sources: article.references?.map(ref => ({
        title: ref.title,
        url: ref.link,
        site_name: ref.site_name || extractLinkDomain(ref.link),
        description: ref.description
      })),
      referenceIndex: index
    });
  };

  const handleViewAllReferences = (index) => {
    setIsPanelOpen(true);
    setSelectedReference({
      sources: article.references?.map(ref => ({
        title: ref.title,
        url: ref.link,
        site_name: ref.site_name || 'Reference',
        description: ref.description
      })),
      referenceIndex: index
    });
  };

  const handleThreadCreated = (newThreadId) => {
    // Update URL without reload
    window.history.replaceState(
      null, 
      '', 
      `/discover/article/${articleId}/chat/${newThreadId}`
    );
    // Update local state
    setActiveThreadId(newThreadId);
    updateHistory();
  };

  // Sync with URL changes
  useEffect(() => {
    setActiveThreadId(urlThreadId);
  }, [ urlThreadId ]);

  const initialConversations = useMemo(() => 
    threadData?.initialData?.results || [], 
  [ threadData?.initialData?.results ]
  );

  // Handle responsive sidebar collapse
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth < 400) {
          collapseSidebar();
        }
      }
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={containerRef} className="relative min-h-screen bg-cream">
      {/* Main content wrapper */}
      <div 
        className={`transition-all duration-300 ease-in-out mx-auto
          ${isPanelOpen ? 'lg:mr-[666px]' : 'lg:mr-0'}`}
      >
        {/* Content container with consistent max-width */}
        <div className="max-w-4xl mx-auto px-4 md:px-8 pb-24">
          {/* Back button */}
          <button
            onClick={() => navigate('/discover')}
            className="group flex items-center gap-2 py-6 text-base-content/70 hover:text-base-content transition-colors duration-200"
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="text-sm font-medium">Back to Discover</span>
          </button>

          {/* Two-column layout container */}
          <div className="relative lg:flex lg:gap-8">
            {/* Main content column */}
            <div className="flex-1">
              {/* Article content */}
              <div className="w-full">
                <h1 className="text-4xl mb-4 leading-snug">{article.title}</h1>
                
                <section className="flex justify-between items-center space-x-4 mb-2 text-neutral-content text-sm">
                  <span className="">{i18n.CURATED_BY[lang]} {article.author}</span>
                  <span className="flex items-center"><ClockIcon className="w-4 h-4 mr-2" />{new Date(article.published_date).toLocaleDateString()}</span>
                </section>

                <p className="text-primary-content">{article.abstract}</p>

                {/* Render Paragraphs */}
                {article.outlines.map((paragraph, index) => (
                  <div
                    key={index}
                    ref={(el) => (paragraphsRef.current[index] = el)}
                    className="transition-all duration-300 ease-in-out my-5"
                  >
                    <h2 className="text-2xl font-medium leading-relaxed">
                      {paragraph.title}
                    </h2>
                    <DisplayFormattedText 
                      text={paragraph.content} 
                      onReferenceClick={handleReferenceClick}
                    />
                    {paragraph.references?.length > 0 && (
                      <div>
                        <ReferencePill />
                      </div>
                    )}
                  </div>
                ))}
                <DisplayFormattedText text={article.conclusion} />
                {/* References Section */}
                {article.references && article.references.length > 0 && (
                  <div className="relative flex text-base mx-auto mb-4 gap-3 group max-w-4xl">
                    <div className="relative flex w-full flex-col text-primary-content">
                      <div className="font-semibold select-none mb-2">References</div>
                      <div className="relative flex-col gap-1">
                        <div className="relative md:grid md:grid-cols-4 gap-2 min-h-24">
                          {/* Show first 3 references */}
                          {article.references.slice(0, 3).map((reference, index) => (
                            <div
                              key={index}
                              className="flex relative flex-col px-2 py-2 my-2 bg-base-300 rounded-sm justify-between cursor-pointer hover:bg-base-200 transition-colors duration-200"
                              onClick={() => handleReferenceClick(reference, index)}
                            >
                              <p className="line-clamp-3 text-left text-xs text-primary-color mb-2">
                                {reference.title}
                              </p>
                              <p className="line-clamp-1 text-left text-xs font-bold text-primary-color">
                                {reference.site_name || new URL(reference.link).hostname}
                              </p>
                            </div>
                          ))}

                          {/* Show View More card if more than 3 references */}
                          {article.references.length > 3 && (
                            <div
                              className="flex relative flex-col px-2 py-2 my-2 bg-base-300 hover:bg-base-200 rounded-sm justify-center items-center cursor-pointer transition-colors duration-200"
                              onClick={() => handleViewAllReferences()}
                            >
                              <div className="text-center">
                                <p className="text-primary-color font-medium mb-1">View All</p>
                                <p className="text-xs text-primary-color/70">
                                  +{article.references.length - 3} more references
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Sticky Sidebar */}
            {/* <div className="hidden lg:block w-48">
              <div className={`sticky top-24 transition-all duration-300 ease-in-out
                ${isPanelOpen ? 'translate-x-0' : 'translate-x-0'}`}
              >
                <nav className="border-l border-base-200">
                  <ul className="space-y-2">
                    {article.outlines.map((paragraph, index) => (
                      <li
                        key={index}
                        className={`block cursor-pointer border-l-2 text-left w-full px-4 my-2 transition-colors duration-200 ease-in-out ${
                          currentIndex === index
                            ? 'text-primary-content font-bold border-primary-content border-l-4 -ml-[1px]'
                            : 'text-secondary-content border-secondary-content hover:text-primary-content hover:border-primary-content'
                        }`}
                        onClick={() => scrollToParagraph(index)}
                      >
                        <span className="text-sm line-clamp-2">
                          {paragraph.title}
                        </span>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      
      {/* Combined Panel */}
      <ChatPanel 
        enableUpload={false}
        isOpen={isPanelOpen}
        onToggle={setIsPanelOpen}
        suggestions={article.followup_questions}
        initialConversations={initialConversations}
        reference={selectedReference}
        assetId={articleId}
        assetType="discover"
        assetTitle={article.title}
        threadId={activeThreadId}
        onThreadCreated={handleThreadCreated}
      />
    </div>
  );
}

// Combined loader that handles both routes
export async function articleDetailLoader({ params }) {
  const { articleId, threadId } = params;
  
  // Always fetch article
  const article = await getDiscoverDetail(articleId);
  
  // If threadId exists, fetch thread data
  if (threadId) {
    const threadTasks = await getThreadTasks(threadId);

    const needFetchLatest =
      threadTasks?.results?.at(0) &&
      ![ 'complete', 'failed' ].includes(threadTasks?.results?.at(0)?.task_status);

    let runningTask = null;
    if (needFetchLatest) {
      runningTask = threadTasks.results.splice(0, 1)?.at(0);
    }
    threadTasks.results.reverse();

    return {
      article,
      threadData: {
        needFetchLatest,
        initialData: threadTasks,
        runningTask,
        threadId,
      },
    };
  }

  // Return just article data if no threadId
  return { 
    article,
    threadData: null,
  };
}
