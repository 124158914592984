export const cards = {
  'clinical-result': {
    zh: {
      title: '临床结果洞察',
      description: '获取并分析临床结果',
      exampleQuestions: [
        '搜索Enhertu的所有临床结果',
        '搜索适应症为NSCLC的PD-1抗体的所有临床结果，并分析哪个药物是最好的治疗NSCLC的PD-1抗体',
        // '搜索适应症为肥胖的所有药物的三期临床数据，并分析哪个药物是减肥的最佳选择'
      ]
    },
    en: {
      title: 'Clinical Results',
      description: 'Access and analyze clinical outcomes',
      exampleQuestions: [
        'Search all clinical results for Enhertu',
        'Search all clinical results for PD-1 antibodies indicated for NSCLC, and analyze which drug is the best-in-class PD-1 antibody for treating NSCLC',
        // 'Search Phase III clinical data for all obesity drugs, and analyze which drug is the best-in-disease in the weight loss field'
      ]
    }
  },
  'drug-compete': {
    zh: {
      title: '药品竞争格局',
      description: '追踪新药进展和竞争格局',
      exampleQuestions: [
        '已经获批的RNAi药物有哪些，他们是由哪些公司开发的，适应症是什么？',
        '有哪些在研的PD-1双抗，他们的适应症是什么？',
        // '辉瑞管线中的ADC药物有哪些？分别靶向哪些靶点？'
      ]
    },
    en: {
      title: 'Drug Pipeline',
      description: 'Track the competitive landscape of drug development',
      exampleQuestions: [
        'What are the approved RNAi drugs, which companies developed them, and what are their indications?',
        'What PD-1 bispecific antibodies are under development, and what are their indications?',
        // 'What ADC drugs are in Pfizer\'s pipeline? Which targets do they respectively target?'
      ]
    }
  },
  industryNews: {
    zh: {
      title: '行业新闻',
      description: '实时监控全球医药行业新闻'
    },
    en: {
      title: 'Industry News',
      description: 'Stay ahead with real-time monitoring of global pharmaceutical industry news'
    }
  },
  conferenceInsights: {
    zh: {
      title: '会议见解',
      description: '即时获取 ASH、ASCO 等国际顶级医学会议内容'
    },
    en: {
      title: 'Conference Insights',
      description: 'Stay up-to-date with leading medical conferences like ASH and ASCO'
    }
  },
  chat: {
    zh: {
      title: '对话',
      description: '与我们的AI助手对话',
      exampleQuestions: [
        { q:'目前批准上市的减肥药都有哪些，哪个最好？', field:'mindsearchofficialsite' },
        { q:'最近3个月AML领域有什么值得读的文章吗？', field:'mindsearchpubmed' },
        // { q:'PD-1抗体有哪些副作用？', field:'mindsearchpubmed' },
        // { q:'PD-1抗体有哪些不良反应？', field:'mindsearch' },
      ]
    },
    en: {
      title: 'Chat',
      description: 'Chat with our AI assistant',
      exampleQuestions: [
        { q:'What weight-loss medications are currently approved for use, and which one is the best?', field:'mindsearchofficialsite' },
        { q:'Are there any articles worth reading in the field of AML over the past three months?', field:'mindsearchpubmed' },
        // { q:'What are the side effects of PD-1 antibodies?', field:'mindsearchofficialsite' },
        // { q:'What are the adverse reactions of PD-1 antibodies?', field:'mindsearchofficialsite' },
      ]
    }
  }
};
