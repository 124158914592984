import { useEffect, useRef, useState } from 'react';

import { CheckCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import DisplayFormattedText from '../AskPage/components/markdownInCell';
import { useLanguage } from '../utils/context/lang';

import { i18n } from './i18n/common';

function LeftPanel({ node, level = 0, nodePath = '0', onNodeSelect, selectedPath, isFirst, isLast }) {
  const { lang } = useLanguage();
  const isSelected = nodePath === selectedPath;

  return (
    <div className="relative bg-cream h-full">
      {level > 0 && (
        <div 
          className={'absolute left-[25px] border-l-2 border-base-content/70'}
          style={{
            top: isFirst ? '50%' : 0,
            bottom: isLast ? '50%' : 0
          }}
        />
      )}
      
      <div
        onClick={() => level > 0 && onNodeSelect(nodePath)}
        className={`w-full px-4 py-3 text-left transition-colors ${level == 0 ? '' : 'cursor-pointer'}`}
      >
        <span className="flex items-center text-sm font-medium text-base-content relative">
          {level > 0 && (node.summary ? (
            <CheckCircleIcon className="w-5 h-5 mr-3 text-success relative z-10 bg-cream shrink-0" />
          ) : (
            <span className="relative z-10 shrink-0 mr-3 w-5 h-5 flex items-center justify-center">
              <span className="absolute inset-0 bg-cream rounded-full" />
              <span className="loading loading-spinner loading-sm text-primary-content relative z-20" />
            </span>
          ))}
          {level == 0 && (
            <div className="flex flex-col">
              <div className="flex items-center">
                <MagnifyingGlassIcon className="h-5 w-5 mr-2 shrink-0" />
                <div>
                  <p className="text-primary-content text-base">{i18n.THOUGHT_BREAK_DOWN[lang]}</p>
                </div>
              </div>
              {/* {node.thought_process && (
                <p className="text-base-content/70 ml-[1.75rem]"><DisplayFormattedText text={node.thought_process} /></p>
              )} */}
            </div>
          )}
          {level > 0 && <p className={`text-base-content/70 hover:text-primary-content ${isSelected ? 'text-primary-content' : ''}`}>{node.query}</p>}
        </span>
      </div>
      {node.children && node.children.length > 0 && (
        <div className="relative">
          {node.children.map((child, idx) => (
            <LeftPanel
              key={idx}
              node={child}
              level={level + 1}
              nodePath={`${nodePath}-${idx}`}
              onNodeSelect={onNodeSelect}
              selectedPath={selectedPath}
              isFirst={idx === 0}
              isLast={idx === node.children.length - 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function RightPanel({ selectedIndex, thoughtProcess, thought }) {
  const { lang } = useLanguage();
  const containerRef = useRef(null);
  const selectedNode = thought?.children?.[selectedIndex];

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [ selectedIndex ]);

  if (!selectedNode) return null;

  return (
    <div ref={containerRef} className="h-full w-full overflow-auto bg-neutral-50">
      <div className="p-6 space-y-6">
        {thoughtProcess && (
          <p className="text-base-content/70"><DisplayFormattedText text={thoughtProcess} /></p>
        )}
        {((selectedNode.search_results && selectedNode.search_results.length > 0) || selectedNode.summary || selectedNode.thought_process) && (
          <div className="space-y-4">
            {selectedNode.thought_process && (
              <>
                <h3 className="font-medium text-base-content">{i18n.THOUGHT_PROCESS[lang]}</h3>
                <DisplayFormattedText text={selectedNode.thought_process} />
              </>
            )}
            {selectedNode.summary && (
              <>
                <h3 className="font-medium text-base-content">{i18n.SEARCH_SUMMARY[lang]}</h3>
                <DisplayFormattedText text={selectedNode.summary} />
              </>
            )}
            {
              (selectedNode.search_results && selectedNode.search_results.length > 0) && <>
                <h3 className="font-medium text-base-content">{i18n.SEARCH_RESULTS[lang]}</h3>
                <ul className="space-y-2">
                  {selectedNode.search_results.map((result, index) => (
                    <li key={index} className="relative pl-2 before:content-[''] before:absolute before:left-0 
                      before:top-[0.6em] before:w-1 before:h-1 before:bg-base-content/30 before:rounded-full">
                      <a
                        href={result.url}
                        className="text-primary-content hover:text-primary-focus hover:underline transition-colors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default function ThoughtNode({ thought }) {
  const data = {
    id: 0,
    search_type: 0,
    query: '北京天气',
    key_word: '',
    thought_process:
      '要了解北京的天气情况，可以从以下几个方面进行查询：当前天气状况、未来几天天气预报、历史天气数据等。',
    search_results: [],
    children: [
      {
        id: 0,
        search_type: 1,
        query: '查询北京未来几天的天气预报',
        key_word: '北京天气预报',
        thought_process: '',
        search_results: [
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询北京今日天气、周末天气、一周天气、40天天气，以及北京的生活指数、健康指数、交通指数、旅游指数等。还可查看北京雷达图、气象预警信号、气象资讯等。',
            title: '北京天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询ABC',
            title: 'ABC天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询XYZ',
            title: 'XYZ天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
        ],
        children: [],
        summary: '',
      },
      {
        id: 2,
        search_type: 1,
        query: '查询北京未来几天的天气预报',
        key_word: '北京天气预报',
        thought_process: '',
        search_results: [
          {
            url: 'http://www.weather.com.cn/weather/101010100.shtml',
            summ: '查询北京今日天气、周末天气、一周天气、40天天气，以及北京的生活指数、健康指数、交通指数、旅游指数等。还可查看北京雷达图、气象预警信号、气象资讯等。',
            title: '北京天气预报,北京7天天气预报,北京15天天气预报,北京天气查询',
            reason: '',
            is_open: false,
            content: '',
          },
        ],
        children: [],
        summary: '',
      },
      // ... other child nodes
    ],
    summary: '',
  };

  const [ hasInitialize, setHasInitialize ] = useState(false);
  const [ selectedPath, setSelectedPath ] = useState('0-0');

  const handleNodeSelect = (path) => {
    setSelectedPath(path);
  };

  useEffect(() => {
    if (thought?.children && thought.children.length > 0 && !hasInitialize) {
      const index = thought.children.length - 1;
      setSelectedPath(`0-${index}`);
      setHasInitialize(true);
    }
  }, [ thought?.children ]);

  if (!thought || !thought.thought_process || thought.thought_process.length === 0) {
    return null;
  }

  return (
    <div className="w-full py-4">
      <div className="w-full h-[650px] flex rounded-xl shadow-sm border border-base-200 overflow-hidden">
        <div className="border-r w-[29%] shrink-0 border-base-200 overflow-auto">
          <div className="h-full">
            <LeftPanel 
              node={thought} 
              onNodeSelect={handleNodeSelect}
              selectedPath={selectedPath}
              isFirst={true}
            />
          </div>
        </div>
        <RightPanel thought={thought} selectedIndex={+selectedPath.split('-')[1]} thoughtProcess={selectedPath === '0-0' ? thought?.thought_process : ''} />
      </div>
    </div>
  );
}
