import React, { Suspense, useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useParams } from 'react-router-dom';

import { useLanguage } from '../../../utils/context/lang';
import { i18n as chatI18n } from '../../i18n/chat';

import { FIELD_COMPONENTS } from './field-mapping';

const DynamicQueryForm = ({ fields, values, defaultExpanded = true ,totalCount, onChange, onSearch, onToggle }) => {
  const { workflowId } = useParams();
  const [ isExpanded, setIsExpanded ] = useState(defaultExpanded);
  const [ localValues, setLocalValues ] = useState(values);
  const { lang } = useLanguage();
  
  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(localValues);
    setIsExpanded(false);
    onToggle?.(false);
  };

  const handleFieldChange = (name, value) => {
    setLocalValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    setLocalValues(values);
  }, [ values ]);

  useEffect(() => {
    if (workflowId) {
      setIsExpanded(true);
    }
  }, [ workflowId ]);

  // Function to get active filter count and summary
  const getActiveFilters = () => {
    const activeFilters = Object.entries(localValues)
      .filter(([ _, value ]) => {
        if (Array.isArray(value)) return value.length > 0;
        if (typeof value === 'object' && value !== null && 'data' in value) {
          return value.data.length > 0;
        }
        return value !== undefined && value !== null && value !== '';
      });
    if (activeFilters.length === 0) return null;

    // Get field display names from the fields prop
    const fieldMap = Object.fromEntries(fields.map(f => [ f.name, f.display ]));
    
    // Get first three filters for preview
    const preview = activeFilters
      .slice(0, 3)
      .map(([ key, value ]) => {
        const displayName = fieldMap[key];
        const displayValue = Array.isArray(value) 
          ? `${value.length} ${chatI18n.SELECTED_COUNT[lang]}` 
          : typeof value === 'object' && value !== null && 'data' in value ? `${value.data.length} ${chatI18n.SELECTED_COUNT[lang]}` : `${value}` ;
        return `${displayName}: ${displayValue}`;
      })
      .join(' • ');

    return {
      count: activeFilters.length,
      preview: preview + (activeFilters.length > 3 ? ` +${activeFilters.length - 3} ${chatI18n.MORE[lang]}` : '')
    };
  };

  const activeFilters = getActiveFilters();

  return (
    <form onSubmit={handleSearch} className="relative bg-white shadow-md rounded-lg overflow-hidden border border-gray-100">
      {/* Filter Toggle Button */}
      <button
        type="button"
        onClick={() => {
          setIsExpanded(!isExpanded);
          onToggle?.(!isExpanded);
        }}
        className={`w-full flex items-center gap-3 px-5 py-3 text-sm 
          hover:bg-gray-50/80 transition-all duration-200 ease-in-out
          ${isExpanded ? 'border-b border-gray-200' : ''}`}
        aria-expanded={isExpanded}
        aria-controls="filter-panel"
      >
        <div className="flex items-center gap-2.5 min-w-fit">
          <div className="flex items-center justify-center w-7 h-7 rounded-full bg-blue-50">
            <MagnifyingGlassIcon className="w-3.5 h-3.5 text-blue-600" />
          </div>
          <span className="text-gray-800 font-medium">
            {chatI18n.SEARCH_AND_FILTER[lang]}
          </span>
          <div className="flex items-center px-2 py-0.5 bg-blue-50 text-blue-700 rounded-full text-xs font-medium">
            {typeof chatI18n.TOTAL_RESULTS_COUNT[lang] === 'function' 
              ? chatI18n.TOTAL_RESULTS_COUNT[lang](totalCount >= 3000 ? '3000+' : totalCount)
              : chatI18n.TOTAL_RESULTS_COUNT[lang].replace('{count}', totalCount >= 3000 ? '3000+' : totalCount)}
          </div>
        </div>

        {activeFilters && (
          <div className="flex items-center gap-2 ml-3 max-w-[60%] overflow-hidden">
            <div className="h-4 w-px bg-gray-200" />
            <div className="flex items-center gap-1.5">
              <span className="inline-flex items-center justify-center w-5 h-5 bg-blue-100 text-blue-700 text-xs font-medium rounded-full">
                {activeFilters.count}
              </span>
              <span className="text-xs text-gray-600 truncate">
                {activeFilters.preview}
              </span>
            </div>
          </div>
        )}

        <div className="flex-1" />
        <div className={`p-1 rounded-full transition-all duration-200 ${isExpanded ? 'bg-gray-100 rotate-180' : 'bg-white'}`}>
          <ChevronDownIcon className="w-5 h-5 text-gray-600" aria-hidden="true" />
        </div>
      </button>

      {/* Filter Fields Container */}
      <div 
        id="filter-panel"
        className={`overflow-hidden transition-all duration-300 ease-in-out
          ${isExpanded ? 'max-h-[60vh] opacity-100' : 'max-h-0 opacity-0'}`}
        aria-hidden={!isExpanded}
      >
        {/* Scrollable content area */}
        <div className="max-h-[calc(60vh-64px)] overflow-y-auto">
          <div className="p-5 space-y-5 @container">
            <div className="grid grid-cols-1 gap-x-6 gap-y-4 max-w-5xl mx-auto @lg:grid-cols-2">
              {fields.map((field) => {
                const FieldComponent = FIELD_COMPONENTS[field.component];
                
                if (!FieldComponent) {
                  console.warn(`No component found for ${field.component}`);
                  return null;
                }

                return (
                  <div key={field.name} className="relative space-y-0.5 group">
                    <label 
                      htmlFor={`field-${field.name}`}
                      className="text-xs font-medium text-gray-600 transition-all duration-200 group-focus-within:text-blue-600"
                    >
                      {field.display}
                    </label>
                    <Suspense fallback={
                      <div className="h-10 animate-pulse bg-gray-100 rounded-lg" />
                    }>
                      <FieldComponent
                        id={`field-${field.name}`}
                        value={localValues[field.name]}
                        onChange={(value) => handleFieldChange(field.name, value)}
                        placeholder={`${chatI18n.FILTER_BY[lang]} ${field.display.toLowerCase()}`}
                        className="w-full bg-white border border-gray-200 rounded-lg 
                          px-3 py-2.5 text-sm placeholder:text-gray-400
                          focus:ring-1 focus:ring-blue-500/30 focus:border-blue-500 
                          transition-all duration-200 hover:border-gray-300
                          shadow-sm"
                      />
                    </Suspense>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Fixed position action buttons */}
        <div className="sticky bottom-0 bg-white border-t border-gray-200 backdrop-blur-sm bg-white/95">
          <div className="flex justify-end gap-3 p-4 max-w-5xl mx-auto">
            <button
              type="button"
              onClick={() => {
                setLocalValues({});
                onChange({});
              }}
              className="px-3.5 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 
                bg-white border border-gray-300 rounded-md hover:bg-gray-50 
                focus:outline-none focus:ring-2 focus:ring-blue-500/30 focus:ring-offset-1
                transition-all duration-200"
            >
              {chatI18n.CLEAR_ALL[lang]}
            </button>
            <button
              type="submit"
              className="px-3.5 py-2 text-sm font-medium text-white 
                bg-blue-600 border border-transparent rounded-md hover:bg-blue-700
                focus:outline-none focus:ring-2 focus:ring-blue-500/30 focus:ring-offset-1
                inline-flex items-center gap-2 shadow-sm transition-all duration-200"
            >
              <MagnifyingGlassIcon className="w-4 h-4" aria-hidden="true" />
              {chatI18n.SEARCH[lang]}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DynamicQueryForm;