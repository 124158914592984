
export const i18n = {
  AUTHOR: {
    en: 'Author',
    zh: '作者'
  },
  JOURNAL: {
    en: 'journal',
    zh: '期刊'
  },
};