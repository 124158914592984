import axios from 'axios';
import { redirect } from 'react-router-dom';

import { hostURL } from './globals.js';
import { getCookie } from './utils/cookie.js';

axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

const currentToken = getCookie('noahAccessToken');
const lang = getCookie('noahLang');
const format = 'json';
let client = axios.create({
  // baseURL: hostURL,
  params: {
    lang,
    format
  },
});

// intercept 401 and redirect to signin page
// the thing is in loader, this intercept doesn't work as expected, loader need a return value.
// client.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     console.log('axios intercept error', error)
//     if (error?.response?.status === 401) {
//       console.log('not authenticated')
//       window.location.href = '/';
//     }
//     return Promise.reject(error);
//   }
// );

if (currentToken) {
  client.defaults.headers.common['Authorization'] = `Token ${currentToken}`;
}

// export async function validateToken() {
//   // validate currentToken, if valid, refresh token, if not, redirect to signin page
// }

export async function signIn(username, passwd) {
  try {
    const response = await client.post('/api/token/', {
      username: username,
      password: passwd,
    });
    const token = response?.data?.token;
    if (token) {
      client.defaults.headers.common[
        'Authorization'
      ] = `Token ${response.data.token}`;
    }
    return { username, token };
  } catch (error) {
    // console.log(error);
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        window.location.href = '/';
      }
    }
  }
  return;
}

export async function signUp(email, password, canReceiveEmail) {
  try {
    const response = await client.post('/api/users/', {
      username: email,
      email,
      password,
      receive_email: canReceiveEmail,
    });
    return response;
  } catch (error) {
    // console.log(error);
    return false;
  }
}

export async function getUserList() {
  try {
    const response = await client.get('/api/users/');
    return response.data.results;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        window.location.href = '/';
      }
    }
  }
}

export function getMyInfo(username) {
  return client.get(`/api/users/${username}`);
  // try {
  //   const response = await client.get(`/api/users/${username}`);
  //   return response.data;
  // } catch (error) {
  //   // console.log(error);
  //   if (error.response) {
  //     return error.response;
  //   }
  // }
}

export async function getHistoryList(page = 1, limit = 10) {
  // http://www.noahai.co/api/tasks/?limit=10&offset=10
  try {
    const response = await client.get(
      `/api/history/?limit=${limit}&offset=${(page - 1) * limit}`
    );
    console.log(response);
    return response.data.data;
  } catch (error) {
    // console.log(error);
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        window.location.href = '/';
      }
    }
  }
}

export async function getDiscoverList(page = 1, limit = 10) {
  try {
    const response = await client.get(
      `/api/discover/?limit=${limit}&offset=${(page - 1) * limit}`
    );
    console.log(response);
    return response.data.results;
  } catch (error) {
    // console.log(error);
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        window.location.href = '/';
      }
    }
  }
}

export async function getHotTopic(page=1, limit=20) {
  try {
    const response = await client.get(`/api/buzz/?limit=${limit}&offset=${(page - 1) * limit}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        window.location.href = '/';
      }
    }
  }
}

export async function getDiscoverDetail(id) {
  try {
    const response = await client.get(`/api/discover/${id}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        window.location.href = '/';
      }
    }
  }
}

// returns all the messages in a thread
export async function getThreadTasks(threadId, offset = 0) {
  let task;
  try {
    let response = await client.get(
      `/api/thread/${threadId}/history/?limit=100&offset=${offset}`
    );
    task = response.data;
    // console.log(task);
    return task;
  } catch (error) {
    // console.log(error);
  }
}

// actually shouldn't call this API, somewhat redundant or should be implemented within getThreadTasks
export async function getThreadMeta(threadId) {
  let task;
  try {
    let response = await client.get(
      `/api/thread/${threadId}/`
    );
    task = response.data;
    console.log(task);
    return task;
  } catch (error) {
    // console.log(error);
  }
}
// initialize with a question
export async function createThread(
  question,
  agent,
  referenceType = null,
  reference = null
) {
  const threadContent = {
    thread_name: question,
    agent,
  };

  // Add context if reference information is provided
  if (reference && referenceType) {
    threadContent.context = {
      reference,
      reference_type: referenceType
    };
  }

  try {
    const response = await client.post('/api/thread/', threadContent);
    const taskInfo = JSON.parse(response.request?.response);
    return taskInfo['id'];
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
}

// returns the answer to the latest question
export async function getFollowup(threadId) {
  console.log('fetching a task!!!!!!!!!!!!!!!!');
  try {
    let response = await client.get(`/api/thread/${threadId}/`);
    let task = response.data;
    return task;
  } catch (error) {
    // console.log(error)
  }
}

export async function register(username, email, password) {
  // https://20.205.130.86/api/users/ post
  const payload = {
    username,
    email,
    password,
  };
  try {
    const response = await client.post('/api/users/', payload);
    // console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function uploadFile(formData, onUploadProgress) {
  return client.post('/api/upload/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

export async function createThreadEmbedding(threadId, fileUUIds) {
  const payload = {
    files: fileUUIds,
  };
  try {
    const response = await client.post(`/api/thread/${threadId}/embedding/`, payload);
    return response;
  } catch (error) {
    // console.log(error);
  }
}

export async function updateTaskComment(taskId, comment) {
  try {
    const response = await client.patch(`/api/task/${taskId}/comment/`, {
      task_comment: comment,
    });
    // console.log(response);
    return response;
  } catch (error) {
    // console.log(error);
  }
}

// export async function createWorkflow(workflow) {
//   try {
//     const response = await client.post('/api/workflowtask/', {
//       name: new Date().toISOString(),
//       workflow, // '临床研究设计比较-NCT'
//     });
//     return response;
//   } catch (error) {
//     console.error('Error creating workflow:', error);
//     // Handle the error as needed, e.g., throw it or return a specific value
//     throw error;
//   }
// }

// export async function fetchWorkflowList(workflowId) {
//   try {
//     const response = await client.get(`/api/workflowtask/${workflowId}`);
//     return response.data;
//   } catch (error) {
//     // console.log(error);
//   }
// }

export async function fetchTypeAhead(query, type) {
  try {
    const response = await client.get(`/api/typeahead/?q=${query}&name=${type}`);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getConferenceSettings(id) {
  // api/conference/{id}/details
  // const mock = {
  //   title: 'ASH 2024',
  //   followup_questions: [ 'What is the most important question to ask?', 'What is the most important question to ask?', 'What is the most important question to ask?' ],
  //   queries: [
  //     { name: 'key_points', type: 'text', description: 'The key points of the paper' },
  //     { name: 'drug', type: 'text', description: 'The drugs mentioned in the paper' },
  //     { name: 'target', type: 'text', description: 'The targets mentioned in the paper' },
  //     { name: 'design', type: 'text', description: 'The design of the paper' },
  //     { name: 'author_and_institution', type: 'text', description: 'The author and institution of the paper' },
  //     { name: 'source_link', type: 'text', description: 'The source link of the paper' },
  //   ]
  // }
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(mock);
  //   }, 1000);
  // });

  try {
    const response = await client.get(`/api/conference/${id}/details`);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getConferenceDetail(id, filters={}, page=1, limit=10) {
  try {
    const query = Object.entries(filters).map(([ key, value ]) => {
      //check if value is valid
      if (Array.isArray(value) && value.length > 0) {
        return `${key}=${value}`;
      } else if (!Array.isArray(value) && value) {
        return `${key}=${value}`;
      }
      return '';
    }).join('&');
    const response = await client.get(`/api/conference/${id}/?${query}&page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getConferenceList() {
  try {
    const response = await client.get('/api/conference/');
    console.log('response', response);
    return response.data;
  } catch (error) {
    // console.log(error);
  }

}

export async function getWorkflowSettings(id) {
  try {
    const response = await client.get(`/api/workflow/${id}/details`);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getWorkflowList() {
  try {
    const response = await client.get('/api/workflow/');
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getWorkflowDetail(id,filters={}, page=1, limit=10) {
  try {
    const response = await client.post(`/api/workflow/${id}/`, {
      filters,
      page,
      limit,
    });
    return response.data;
  } catch (error) {
    console.log('error', error);
    return {
      results: [],
      total: 0,
      error: error?.response?.status,
    }
  }
}

export async function getClinicalDetail(id) {
  try {
    const response = await client.post('/api/workflow/clinical-result/', {
      filters: { id: id },
      page: 1,
      limit: 1,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getCatalystDetail(id) {
  try {
    const response = await client.post('/api/workflow/catalyst/', {
      filters: { id: id },
      page: 1,
      limit: 1,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getReport(attachmentKey, threadId) {
  try {
    const response = await client.post('/api/report/', {
      key: attachmentKey,
      thread: threadId,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getClinicalAssociations() {
  try {
    const response = await client.get('/api/clinical-guidance/');
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getClinicalGuidances(id) {
  try {
    const response = await client.get(`/api/clinical-guidance/${id}/`);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getClinicalGuidanceTrees(associationId, diseaseId) {
  try {
    const response = await client.get(`/api/clinical-guidance/${associationId}/${diseaseId}/`);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}

export async function getClinicalGuidanceTreeDetail(associationId, diseaseId, treeId) {
  try {
    const response = await client.get(`/api/clinical-guidance/${associationId}/${diseaseId}/${treeId}/`);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
}
