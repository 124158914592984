import { useEffect, useRef, useState } from 'react';

import './main.css';
import {
  Link,
  useLoaderData,
} from 'react-router-dom';

import {
  getConferenceList,
  getDiscoverList,
  getHotTopic,
  getThreadMeta,
  getThreadTasks,
  getWorkflowList,
} from '../api.js';
import { ChatCard, ConferenceCard, DiscoverCard, WorkflowCard } from '../AskPage/components/cards.js';

export function Home() {
  const {
    hotArticles,
    workflows,
    conferences,
  } = useLoaderData();
  
  return (
    <div className="flex flex-col min-h-[800px] h-screen bg-cream">
      {/* Main content area with minimum spacing from top */}
      <main className="flex-1 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 bg-cream">
        <div className="max-w-5xl mx-auto">
          {/* Cards Grid - with fixed vertical spacing */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Workflow Cards */}
            {workflows?.map(workflow => (
              <WorkflowCard key={workflow.id} workflow={workflow} />
            ))}
              
            {/* Conference Card */}
            <ConferenceCard conference={conferences} />
              
            {/* Discover Card */}
            <DiscoverCard article={hotArticles} />

            <ChatCard />
            {/* <Link to="/ask" className="flex justify-center items-center h-32">
              ask anything
            </Link>

            <Link to="/gen" className="flex justify-center items-center h-32">
              gen report
            </Link> */}
          </div>
        </div>
      </main>
    </div>
  );
}

export async function HomeLoader({ params }) {
  let runningTask = {};
  if (!params.askId) {
    const hotTopics = await getHotTopic(1, 3);
    const hotArticles = await getDiscoverList(1, 3);
    const workflows = await getWorkflowList(1, 3);
    const conferences = await getConferenceList(1, 3);
    console.log(hotTopics, hotArticles, workflows, conferences);
    return {
      needFetchLatest: false,
      initialData: null,
      runningTask: null,
      threadAgentType: null,
      hotTopics: hotTopics?.results,
      hotArticles:hotArticles,
      workflows: workflows,
      conferences: conferences,
    };
  }
  const initialData = await getThreadTasks(params.askId);
  const thread = await getThreadMeta(params.askId);
  const needFetchLatest =
    initialData?.results?.at(0) &&
    ![ 'complete', 'failed' ].includes(initialData?.results?.at(0)?.task_status);
  if (needFetchLatest) {
    runningTask = initialData.results.splice(0, 1)?.at(0);
  }
  initialData.results.reverse();
  // console.log("===", {needFetchLatest,initialData});
  return {
    needFetchLatest,
    initialData,
    runningTask,
    threadAgentType: thread?.agent,
    hotTopics: null,
    hotArticles: null,
  };
}
