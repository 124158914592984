import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { useLanguage } from '../../utils/context/lang';
import { useLayout } from '../../utils/context/layout';
import { i18n } from '../i18n/reference';

import PdfViewer from './pdf';

const ReferenceView = ({ 
  reference,
  isChatVisible,
  onClose,
  onOpenChat,
}) => {
  const MIN_WIDTH = 400;
  const MAX_WIDTH = 800;
  const DEFAULT_WIDTH = 800;
  
  const { collapseSidebar } = useLayout();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ width, setWidth ] = useState(DEFAULT_WIDTH);
  const resizingRef = useRef(false);
  const referencesContainerRef = useRef(null);
  const startPosRef = useRef(0);
  const startWidthRef = useRef(0);
  const { lang } = useLanguage();

  // Auto-detect reference type
  const isPDF = reference.sources?.[reference.referenceIndex]?.url?.toLowerCase().includes('.pdf');
  const hasSourcesList = Array.isArray(reference.sources) && reference.sources.length > 0;
  const urlObj = isPDF ? new URL(reference.sources?.[reference.referenceIndex]?.url) : null;
  const pageNumber = urlObj ? parseInt(urlObj.searchParams.get('page_num')) || 1 : null;

  // Scroll to highlighted reference when reference list is shown
  useEffect(() => {
    if (hasSourcesList && reference.referenceIndex !== undefined && referencesContainerRef.current) {
      const referenceElements = referencesContainerRef.current.children;
      if (referenceElements[reference.referenceIndex]) {
        referenceElements[reference.referenceIndex].scrollIntoView({ 
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [ hasSourcesList, reference.referenceIndex ]);

  useEffect(() => {
    if (isPDF) {
      collapseSidebar();
    }
  }, [ isPDF ]);

  const handleMouseDown = useCallback((e) => {
    resizingRef.current = true;
    startPosRef.current = e.clientX;
    startWidthRef.current = width;
    document.body.style.cursor = 'ew-resize';
    document.body.style.userSelect = 'none';
  }, [ width ]);

  const handleMouseMove = useCallback((e) => {
    if (!resizingRef.current) return;
    const delta = e.clientX - startPosRef.current;
    const newWidth = Math.min(Math.max(startWidthRef.current - delta, MIN_WIDTH), MAX_WIDTH);
    setWidth(newWidth);
  }, []);

  const handleMouseUp = useCallback(() => {
    resizingRef.current = false;
    document.body.style.cursor = '';
    document.body.style.userSelect = '';
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [ handleMouseMove, handleMouseUp ]);

  return (
    <div
      className={`flex flex-col h-screen border-l border-base-200 relative transition-all duration-200 ${
        isPDF ? '' : 'w-[400px]'
      } ${resizingRef.current ? 'select-none' : ''}`}
      style={isPDF ? { 
        width: `${width}px`,
        transition: resizingRef.current ? 'none' : 'width 0.2s ease'
      } : undefined}
    >
      {isPDF && (
        <div
          className={`absolute -left-3 top-0 w-6 h-full z-10 cursor-ew-resize hover:bg-primary-color/20 ${
            resizingRef.current ? 'bg-primary-color/20' : ''
          }`}
          onMouseDown={handleMouseDown}
        />
      )}
      <div className="flex-none p-4 border-b border-base-200">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">
            {hasSourcesList ? 'References' : 'Reference'}
          </h3>
          <div className="flex gap-2">
            {!isChatVisible && (
              <button
                onClick={onOpenChat}
                className="text-sm px-2 py-1 hover:bg-base-200 rounded transition-colors duration-200"
              >
                Open Chat
              </button>
            )}
            <button
              onClick={onClose}
              className="p-1.5 hover:bg-base-200 rounded-full transition-colors duration-200"
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 relative overflow-hidden">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-base-100/50 z-10">
            <div className="flex flex-col items-center">
              <div className="loading loading-spinner loading-lg mb-4"></div>
              <div className="text-sm text-base-content/70">
                Loading reference...
              </div>
            </div>
          </div>
        )}

        {isPDF ? (
          <div className="h-full">
            <PdfViewer 
              url={reference.sources?.[reference.referenceIndex]?.url}
              pageNumber={pageNumber}
              onLoadStart={() => setIsLoading(true)}
              onLoadComplete={() => setIsLoading(false)}
              containerWidth={width}
            />
          </div>
        ) : hasSourcesList ? (
          <div className="h-full overflow-y-auto">
            <div 
              ref={referencesContainerRef}
              className="p-4 space-y-4 h-full overflow-y-scroll bg-neutral-50"
            >
              {reference.sources?.map((source, index) => (
                <div
                  href={source.url}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  className={`p-4 rounded-lg transition-colors duration-200 ${
                    index === reference.referenceIndex 
                      ? 'bg-primary-color/10 border-2 border-primary-color'
                      : 'bg-base-200/50 hover:bg-base-200'
                  }`}
                >
                  <a
                    href={source.url}
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium mb-2 line-clamp-2 hover:underline"
                  >
                    {source.title}
                  </a>
                  {source.summary && (
                    <p className="text-sm text-base-content/70 line-clamp-5">{source.summary}</p>
                  )}
                  {/* Basic Information */}
                  <div className="mt-2 space-y-2">
                    <div className="flex items-center flex-wrap">
                      {source.author && (
                        <div className="flex items-center gap-2 mr-2">
                          <span className="text-xs font-medium text-gray-500 shrink-0">{i18n.AUTHOR[lang]}:</span>
                          <span className="text-sm text-gray-700">{source.author}</span>
                        </div>
                      )}
                      {source.site_name && (
                        <div className="flex items-center gap-2">
                          <span className="text-xs font-medium text-gray-500 shrink-0">{i18n.JOURNAL[lang]}:</span>
                          <span className="text-sm text-gray-700">{source.site_name}</span>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                      {source.pub_data && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          {source.pub_data}
                        </span>
                      )}
                      {source.cite_score && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          IF: {source.cite_score}
                        </span>
                      )}
                    </div>
                    {source.doi && (
                      <div className="flex items-center gap-2">
                        <span className="text-xs font-medium text-gray-500">DOI:</span>
                        <span className="text-sm text-blue-600">{source.doi}</span>
                      </div>
                    )}
                    {source.pubmed_id && (
                      <div className="flex items-center gap-2">
                        <span className="text-xs font-medium text-gray-500">PMID:</span>
                        <span className="text-sm text-blue-600">{source.pubmed_id}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-4">
            <h4 className="font-medium mb-2">{reference.title}</h4>
            <a
              href={reference.link || reference.url}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-primary-color hover:underline"
            >
              {reference.link || reference.site_name}
            </a>
            {reference.description && (
              <p className="mt-4 text-sm">{reference.description}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferenceView;